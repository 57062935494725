<div id="telaLogin">
  <div class="container d-flex align-items-center" style="height: 100vh;">
    <div class="row justify-content-center" style="padding-bottom: 3em;">
      <div class="col-7">
        <div class="row align-items-center">
          <div class="col-6">
            <p class="textoLogin">AVISO: Você está prestes a entrar em um site privado e restrito. A utilização do site
              é autorizada apenas pela empresa Sety Comércio e Tecnologia, seus agentes autorizados e para fins
              comerciais. Tentativa de acesso não autorizado e/ou alterações do site ou em seu conteúdo é estritamente
              proibido. Usuários não autorizados estão sujeitos as leis criminais e civis. A empresa poderá fornecer os
              elementos das atividades monitoradas como prova para Policiais, Investigadores e Oficiais de Justiça,
              apresentando documentos em processos judiciais e administrativos, se necessário. O acesso a esta aplicação
              é proibido a partir de um ambiente não confiável, incluindo, mas não se limitando aos cyber cafés,
              quiosques públicos, etc.
              Sistema Sety. Licenciado para a Sety Comércio e Tecnologia.
              Todos os Direitos Reservados.
              Este site é propriedade da Sety Comércio e Tecnologia.</p>

            <p class="subtxtLogin">Sistema Sety. Licenciado para a Sety Comércio e Tecnologia.</p>

            <p class="subtxtLoginBold">
              Todos os Direitos Reservados.
            </p>
            <p class="subtxtLoginBold">
              Este site é propriedade da Sety Comércio e Tecnologia.
            </p>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-header">
                <p class="textAlign">
                  <img class="imgSize" src="/assets/images/sety-logo.svg" />
                </p>
              </div>
              <div class="card-body">
                <form [formGroup]="form">
                  <div class="row">
                    <div class="col-12">
                      <label class="form-label"></label>
                      <input name="login" formControlName="login" type="email" class="form-control"
                        placeholder="E-mail">
                    </div>
                    <div class="col-12">
                      <label class="form-label"></label>
                      <input name="senha" formControlName="senha" type="password" class="form-control"
                        placeholder="Senha" (keyup.enter)="focusableSubmit.click()">
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button class="btn btn-success col-12" (click)="verifyLogin()" #focusableSubmit>Login</button>
              </div>
              <div style="text-align: end;">
                <a routerLink="esqueceu-senha">Esqueceu sua
                  senha?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="footerLogin">
      <ul>
        <li>
          <a href="https://setytecnologia.com.br/" target="_blank" rel="noreferer">Sobre nós</a>
        </li>
        <li>
          <button class="btn btn-link termos"
           data-bs-toggle="modal" data-bs-target="#modalTermo"
            (click)="getTermo('TERMO 1')">Informações Legais</button>
        </li>
        <li>
          <button class="btn btn-link termos" data-bs-toggle="modal" data-bs-target="#modalTermo"
            (click)="getTermo('TERMO 2')">Política de Privacidade</button>
        </li>
      </ul>
      <div class="leftFooter">
        <p>
          Versão do sistema: {{ versaoAtual }} Data de Lançamento: {{ releaseData }}
        </p>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="modalTermo" tabindex="-1" data-dismiss="modal">
  <div class=" modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #fecharModal class="btn-close" data-bs-dismiss="modal" hidden aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row" id="textoTermo">
          <span [innerHTML]="ignorSanitize()"></span>
        </div>
      </div>
    </div>
  </div>
</div>