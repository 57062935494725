import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SimCardEntity } from './../../entitys/simcard.entity';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session/session.service';
import { InterfaceCustomActions, crudDispatchEvent } from '@datagrupo/dg-crud';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-simcard-main',
  templateUrl: './simcard-main.component.html',
  styleUrls: ['./simcard-main.component.scss']
})
export class SimcardMainComponent implements OnInit {

  public mapEntity = new SimCardEntity()

  customActions: InterfaceCustomActions[] = []

  public permissao: any;

  constructor(
    public http: HttpClient,
    private route: Router,
    private session: SessionService
  ) {
    this.permissao = session.user.permissoes?.find(v => v.identificador?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() == 'simcard')
    if (this.permissao?.read) {
      this.customActions.push(
        {
          name: 'Editar',
          action: !!this.permissao.update ? (row: SimCardEntity) => this.navigate(row.id) : () => { },
          permission: !!this.permissao.update ? true : false
        },
        {
          name: 'Excluir',
          action: !!this.permissao.delete ? (row: SimCardEntity) => this.delete(row) : () => { },
          permission: !!this.permissao.delete ? true : false
        },
      )
    }
  }

  ngOnInit(): void { }

  dictionary = (data: any) => {
    return data;
  };

  navigate(index: string | number | undefined) {
    this.route.navigate([`/user/simcard/${index}`])
  }

  delete(row: SimCardEntity) {
    Swal.fire({
      title: 'Excluir registro',
      text: 'Deseja excluir esse registro? Esta ação é permanente.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        this.http.delete(environment.api.URI + `simcard/${row.id}`).subscribe(
          resp => {
          Swal.fire({
            title: 'Deletado com sucesso',
            text: 'Registro deletado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          });
          crudDispatchEvent('simcard')
        },
        (error: HttpErrorResponse) => {
          if (error.status === 400) {
            Swal.fire({
              title: 'Erro',
                text: 'Não é possível excluir esse registro.',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
            });
          }
        }
        );
      }

    })
  }

}
