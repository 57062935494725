import { GenericHttpService } from './../../../../services/generic-http.service';
import { EmpresaEntity } from './../../entitys/empresa.entity';
import { ViagensEntity } from './../../entitys/viagens.entity';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractInsertEdit, HttpClient, InsertEditConfig } from '@datagrupo/dg-crud';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MotoristasEntity } from '../../entitys/motoristas.entity';
import { CaminhoesEntity } from '../../entitys/caminhoes.entity';
import { LacresEntity } from '../../entitys/lacres.entity';
import { RotasEntity, interfRotas } from '../../entitys/rotas.entity';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { formataData } from 'src/app/helpers/helpers';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-viagens-inser-edit',
  templateUrl: './viagens-inser-edit.component.html',
  styleUrls: ['./viagens-inser-edit.component.scss']
})
export class ViagensInserEditComponent extends AbstractInsertEdit<ViagensEntity> implements OnInit {

  currentDate: any = new Date();

  canEdit: boolean = true;

  @ViewChild('modalParada') modalParada!: ElementRef;

  listaParadas: interfRotas[] = [];

  entityRota: any;

  listEmpresa: EmpresaEntity[] = [];
  listMotorista: MotoristasEntity[] = [];
  listCaminhoes: CaminhoesEntity[] = [];
  listLacres: LacresEntity[] = [];
  listRotas: RotasEntity[] = [];

  public nomeRota = new FormControl({ value: '', disabled: true }, Validators.required);

  public form = new FormGroup({
    nome: new FormControl({ value: '', disabled: false }, [Validators.required]),
    empresa: new FormControl({ value: '', disabled: false }, [Validators.required]),
    motorista: new FormControl({ value: '', disabled: false }, [Validators.required]),
    codigo: new FormControl({ value: '', disabled: true }, [Validators.required]),
    caminhao: new FormControl({ value: '', disabled: false }, [Validators.required]),
    dataInicio: new FormControl({ value: '', disabled: false }, [Validators.required]),
    lacre: new FormControl({ value: '', disabled: false }, [Validators.required]),
    rota: new FormControl({ value: '', disabled: false }, [Validators.required])
  })

  formParadas = new FormGroup({
    index: new FormControl(''),
    cep: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    logradouro: new FormControl({ value: '', disabled: true }, Validators.required),
    numero: new FormControl(''),
    complemento: new FormControl(''),
    bairro: new FormControl({ value: '', disabled: true }, Validators.required),
    cidade: new FormControl({ value: '', disabled: true }, Validators.required),
    estado: new FormControl({ value: '', disabled: true }, Validators.required)
  })

  constructor(
    public config: InsertEditConfig,
    public route: ActivatedRoute,
    public router: Router,
    private http: GenericHttpService,
    private service: HttpClient
  ) {
    super(config, { path: environment.api.URI, context: 'viagem' })
    http.get('empresa').subscribe(resp => {
      this.listEmpresa = resp.data
    })
    http.get('motorista').subscribe(resp => {
      this.listMotorista = resp.data
    })
    http.get('caminhao').subscribe(resp => {
      this.listCaminhoes = resp.data
    })
    http.get('lacre').subscribe(resp => {
      this.listLacres = resp.data
    })
    http.get('rota').subscribe(resp => {
      this.listRotas = resp.data
    })
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.gerarCodigo()
  }

  initNewEntity(): void {
    this.entity = new ViagensEntity()
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
      empresa: this.entity.empresa?.id,
      lacre: this.entity.lacre?.id,
      motorista: this.entity.motorista?.id,
      caminhao: this.entity.caminhao?.id,
      rota: this.entity.rota?.id
    })
    this.verificarData()

  }

  verificarData() {
    let dataAtual = new Date();
    let dataForm = this.form.value.dataInicio
    if (dataForm != '--') {
      if (
        Number(formatDate(dataAtual, 'yyyyMMdd', 'en_US')) >
        Number(formatDate(dataForm, 'yyyyMMdd', 'en_US'))) {

        this.canEdit = false;
        this.form.controls['nome'].disable();
        this.form.controls['empresa'].disable();
        this.form.controls['motorista'].disable();
        this.form.controls['caminhao'].disable();
        this.form.controls['dataInicio'].disable();
        this.form.controls['lacre'].disable();
        this.form.controls['rota'].disable();
      }
    }


  }

  saveOrUpdateEntity() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }


    const { routeName, ...form } = this.form.getRawValue();

    this.entity = {
      ...this.entity,
      ...form,
    }

    if (this.entity.id == undefined) {
      this.http.post('viagem', this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Viagem cadastrada com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
        (e) => {
          if (!!e.error.error) {
            Swal.fire({
              title: 'Erro ao cadastrar',
              text: 'O código da viagem já existe.',
              icon: 'error',
              confirmButtonColor: 'blue'
            })
          }
          if (e.status == 409) {
            Swal.fire({
              title: 'Erro ao cadastrar',
              text: 'Os dados inseridos já estão cadastrados.',
              icon: 'error',
              confirmButtonColor: 'blue'
            })
          };
          if (e.status == 400) {
            Swal.fire({
              title: 'Erro ao cadastrar',
              text: 'Os dados inseridos não são válidos.',
              icon: 'error',
              confirmButtonColor: 'blue'
            })
          }
        }
      )
    } else {
      this.http.patch(`viagem/${this.entity.id}`, this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Viagem editada com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
        (e) => {
          if (!!e.error.error) {
            Swal.fire({
              title: 'Erro ao editar',
              text: 'Ocorreu um erro ao editar o registro, por favor tente novamente mais tarde.',
              icon: 'error',
              confirmButtonColor: 'blue'
            })
          }
          if (e.status == 409) {
            Swal.fire({
              title: 'Erro ao editar',
              text: 'O lacre já está vinculado a uma viagem.',
              icon: 'error',
              confirmButtonColor: 'blue'
            })
          }
        }
      )
    }

  }

  editarRota(val: number) {
    this.http.get(`rota/${val}`).subscribe(resp => {
      this.entityRota = resp
      this.listaParadas = resp.rotas
      if (resp.nome != undefined) {
        this.nomeRota.setValue(resp.nome);
        this.nomeRota.disable();
      }
    })
  }

  openModalRota() {
    this.formParadas.reset();
    this.nomeRota.reset();
    this.nomeRota.enable();
    this.entityRota = {};
    this.listaParadas = [];
  }

  addParada() {
    if (this.formParadas.invalid) {
      this.formParadas.markAllAsTouched();
      return;
    }

    const { index, ...val } = this.formParadas.getRawValue();
    if (index != undefined) {
      this.listaParadas.splice(index, 1, val)
    } else {
      this.listaParadas.push(val)
    }
    this.formParadas.reset()

    this.formParadas.controls['logradouro'].disable();
    this.formParadas.controls['bairro'].disable();
    this.formParadas.controls['cidade'].disable();
    this.formParadas.controls['estado'].disable();
  }

  editParada(data: interfRotas, index: number) {
    this.formParadas.patchValue({
      ...data,
      index: index
    })
  }

  excluirLinha(index: number) {
    Swal.fire({
      title: 'Deseja excluir esse registro?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        this.listaParadas.splice(index, 1);
      }
    })
  }

  getCep() {
    const val = this.formParadas.value
    if (val.cep.length == 8) {
      this.service.get(`https://viacep.com.br/ws/${val.cep}/json`).subscribe((resp: any) => {
        this.formParadas.patchValue({
          ...resp,
          estado: resp.uf,
          cidade: resp.localidade
        })
        if (!!resp.erro || resp.logradouro == '') {
          Swal.fire({
            title: 'CEP não encontrado',
            text: 'deseja cadastrar manualmente?',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            icon: 'question',
            showCancelButton: true,
            cancelButtonColor: 'red',
            confirmButtonColor: 'blue'
          }).then(response => {
            if (response.isConfirmed) {
              this.formParadas.controls['logradouro'].enable();
              this.formParadas.controls['bairro'].enable();
              this.formParadas.controls['cidade'].enable();
              this.formParadas.controls['uf'].enable();
            }
          })
        }
      })
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listaParadas, event.previousIndex, event.currentIndex);
  }

  gerarCodigo() {
    let cod = String(Math.floor(Math.random() * 100000 + 1));
    if (this.form.getRawValue().codigo == undefined || this.form.getRawValue().codigo == '') {
      this.form.patchValue({
        codigo: cod
      })
    }
  }

  salveOrUpdateRota() {
    if (this.nomeRota.invalid) {
      this.nomeRota.markAllAsTouched();
      return;
    }
    if (this.listaParadas.length <= 0) {
      Swal.fire({
        title: 'Preencha as paradas',
        text: 'Não existe nenhuma parada preenchida, por favor adicione paradas.',
        icon: 'error',
      })
      return;
    }

    this.entityRota = {
      ...this.entityRota,
      nome: this.nomeRota.value,
      rotas: this.listaParadas
    }

    if (this.entityRota.id) {
      this.http.patch(`rota/${this.entityRota.id}`, this.entityRota).subscribe(resp => {
        Swal.fire({
          title: 'Rota editada com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.modalParada.nativeElement.click();
          this.http.get('rota').subscribe(resp => {
            this.listRotas = resp.data
          })
        })
      })
    } else {
      this.http.post('rota', this.entityRota).subscribe(resp => {
        Swal.fire({
          title: 'Rota cadastrada com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.modalParada.nativeElement.click();
          this.http.get('rota').subscribe(resp => {
            this.listRotas = resp.data
          })
        })
      })
    }

  }
}

