import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpresaMainComponent } from './empresa-main/empresa-main.component';
import {NgxMaskModule} from "ngx-mask";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {DgCrudModule} from "@datagrupo/dg-crud";
import { EmpresaInserEditComponent } from './empresa-inser-edit/empresa-inser-edit.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'src/app/ui/shared/shared.module';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';



@NgModule({
  declarations: [
    EmpresaMainComponent,
    EmpresaInserEditComponent
  ],
  imports: [
    CommonModule,
    NgxMaskModule,
    RouterModule,
    ReactiveFormsModule,
    DgCrudModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule,
    PrivateLayoutModule
  ]
})
export class EmpresaModule { }
