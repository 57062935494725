<nav id="navBar-header" class="navbar navbar-white bg d-flex">
  <div class="container-fluid" style="margin: auto;">
    <button class="navbar-brand btn btn-success botaoNavbar" (click)="changeSidebar.emit()">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="justify-content-center">
      <div>
        <p class="textNavbar">Seja bem vindo ao Painel Administrativo Sety.</p>
      </div>
    </div>

    <div class="dropdown">
      <button class="btn dropdown-toggle d-flex align-items-center" type="button" id="dropdownMenu-navBar-header"
        data-bs-toggle="dropdown" aria-expanded="false">
        <mat-icon>settings</mat-icon>
      </button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu-navBar-header">
        <li><a class="dropdown-item" [routerLink]="perfilUser">
            <mat-icon>person</mat-icon> Atualizar Perfil
          </a></li>
        <li><button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#alterarSenhaModal">
            <mat-icon>refresh</mat-icon> Atualizar Senha
          </button></li>
        <li *ngIf="perfil == 'SETY'"><button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalTermo" #modalTermos (click)="openTermoModal()">
            <mat-icon>description</mat-icon> Atualizar Documentos
          </button></li>
        <li><button class="dropdown-item" (click)="logout()">
            <mat-icon>logout</mat-icon> Sair
          </button></li>
      </ul>
    </div>
  </div>
</nav>

<div class="modal" id="alterarSenhaModal" tabindex="-1">
  <div class=" modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5>ATUALIZAR SENHA</h5>
      </div>
      <div class="modal-body">
        <div class="row">
          <form [formGroup]="form">
            <div>
              <label class="form-label">Senha atual</label>
              <div class="input-group">
                <input [type]="showOldPassword ? 'text' : 'password'" style="border-radius: 0.3em" class="form-control"
                  formControlName="senhaAntiga">
                <div class="input-group-append">
                  <button class="input-group-text ajust-icon-input" (click)="showOldPassword = !showOldPassword">
                    <span class=" material-symbols-outlined">
                      {{!showOldPassword ? 'visibility' : 'visibility_off'}}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <label class="form-label">Senha nova</label>
              <div class="input-group">
                <input [type]="showPassword ? 'text' : 'password'" style="border-radius: 0.3em" class="form-control"
                  formControlName="senha">
                <div class="input-group-append">
                  <button class="input-group-text ajust-icon-input" (click)="showPassword = !showPassword">
                    <span class=" material-symbols-outlined">
                      {{!showPassword ? 'visibility' : 'visibility_off'}}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <label class="form-label">Confirmar senha</label>
              <div class="input-group">
                <input [type]="showPassword2 ? 'text' : 'password'" style="border-radius: 0.3em" class="form-control"
                  formControlName="confirmaSenha">
                <div class="input-group-append">
                  <button class="input-group-text ajust-icon-input" (click)="showPassword2 = !showPassword2">
                    <span class=" material-symbols-outlined">
                      {{!showPassword2 ? 'visibility' : 'visibility_off'}}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary" #closeModal data-bs-dismiss="modal"
          (click)="fecharModal('SENHA')">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="atualizaSenha()">Alterar Senha</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modalTermo" tabindex="-1">
  <div class=" modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5>ATUALIZAR TERMOS</h5>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="d-flex pb-2">
            <div class="col-6">
              <button class="btn btn-outline-success" (click)="getTermo('INFO')">Informações Legais</button>
            </div>
            <div class="col-6">
              <button class="btn btn-outline-success" (click)="getTermo('POLITICA')" >Politica de Privacidade</button>
            </div>
          </div>
          <angular-editor [config]="editorConfig" [formControl]="texto"></angular-editor>
        </div>
      </div>
      <div class="modal-footer justify-content-end">
        <button type="button" class="btn btn-secondary" #modalTermo data-bs-dismiss="modal"
          (click)="fecharModal('TERMO')">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarTermos()">Salvar</button>
      </div>
    </div>
  </div>
</div>