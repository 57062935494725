import { EmpresaEntity } from './../../entitys/empresa.entity';

import { Component, OnInit, ViewChild } from '@angular/core';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { crudDispatchEvent, HttpClient, InterfaceCustomActions } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/core/services/session/session.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-empresa-main',
  templateUrl: './empresa-main.component.html',
  styleUrls: ['./empresa-main.component.scss']
})
export class EmpresaMainComponent implements OnInit {

  public mapEntity = new EmpresaEntity()

  customActions: InterfaceCustomActions[] = []

  @ViewChild('filter') filter: any

  permissao: any;

  constructor(
    private service: HttpClient,
    public route: Router,
    private session: SessionService
  ) {
    this.permissao = session.user.permissoes?.find(v => v.identificador?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() == 'empresas')
    if (this.permissao?.read) {
      this.customActions.push(
        {
          name: 'Editar',
          action: !!this.permissao.update ? (row: EmpresaEntity) => this.navigate(row.id) : () => { },
          permission: !!this.permissao.update ? true : false
        },
        {
          name: 'Excluir',
          action: !!this.permissao.delete ? (row: EmpresaEntity) => this.delete(row) : () => { },
          permission: !!this.permissao.delete ? true : false
        },
        {
          name: 'Ativar',
          action: !!this.permissao.ativo ? (row: EmpresaEntity) => this.ativaDesativa(row) : () => {},
          permission: !!this.permissao.ativo && ((val: EmpresaEntity) => !val.ativo) ? true : false
        },
        {
          name: 'Desativar',
          action: !!this.permissao.ativo ? (row: EmpresaEntity) => this.ativaDesativa(row) : () => {},
          permission: !!this.permissao.ativo && ((val: EmpresaEntity) => val.ativo) ? true : false
        },
        
      )
    }
    this.mapEntity.addActions([
      {
        name: 'Ativar',
        action: (val: EmpresaEntity) => this.ativaDesativa(val),
        permission: (val: EmpresaEntity) => !val.ativo
      },
      {
        name: 'Desativar',
        action: (val: EmpresaEntity) => this.ativaDesativa(val),
        permission: (val: EmpresaEntity) => val.ativo
      }
    ])
  }

  ngOnInit(): void { }

  dictionary = (data: any) => {
    return data || []
  }

  ativaDesativa(row: EmpresaEntity) {
    const data = { id: row.id, status: (row.ativo = !row.ativo) }
    this.service.patch(environment.api.URI + `empresa/${row.id}`, data).subscribe(resp => {
      crudDispatchEvent('mapEntity')
    })
  }

  navigate(index: string | number | undefined) {
    this.route.navigate([`/user/empresas/${index}`])
  }

  delete(row: EmpresaEntity) {
    Swal.fire({
      title: 'Excluir registro',
      text: 'Deseja excluir esse registro? Esta ação é permanente.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        this.service.delete(environment.api.URI + `empresa/${row.id}`).subscribe(
          resp => {
            Swal.fire({
              title: 'Deletado com sucesso',
              text: 'Registro deletado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            });
            crudDispatchEvent('mapEntity');
          },
          (error: HttpErrorResponse) => {
            if (error.status === 400) {
              Swal.fire({
                title: 'Erro',
                text: 'Dados vinculados não podem ser excluídos',
                icon: 'error',
                showConfirmButton: true
                
              });
            }
          }
        );
      }
    });
  }
  
}
