import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartSessionComponent } from './start-session/start-session.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";


@NgModule({
  declarations: [
    StartSessionComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class AuthModule { }
