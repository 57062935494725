<div class="container">
    <div class="row">
        <div>
            <div class="row pb-3">
                <div class="col-xl-4">
                    <div class="cardDash">
                        <div class="row">
                            <div class="col-xl-6">
                                <h4>Lacres</h4>
                            </div>
                            <div class="col-xl-6 d-flex align-items-center justify-content-center">
                                <label class="badge-success">Cadastradas</label>
                            </div>
                        </div>
                        <hr>
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-xl-2">
                                <span class="material-symbols-outlined">
                                    lock_open
                                </span>
                            </div>
                            <div class="col-xl-2">
                                <h4> {{!!lacreData?.lacresCadastrados ? lacreData?.lacresCadastrados : 0 }} </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="cardDash">
                        <div class="row">
                            <div class="col-xl-6">
                                <h4>Lacres</h4>
                            </div>
                            <div class="col-xl-6 d-flex align-items-center justify-content-center">
                                <label class="badge-primary">Disponíveis</label>
                            </div>
                        </div>
                        <hr>
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-xl-2">
                                <span class="material-symbols-outlined">
                                    lock_open
                                </span>
                            </div>
                            <div class="col-xl-2">
                                <h4>{{!!lacreData?.lacresDisponiveis ? lacreData?.lacresDisponiveis : 0 }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="cardDash">
                        <div class="row d-flex align-items-center">
                            <div class="col-xl-5">
                                <h4>Lacres</h4>
                            </div>
                            <div class="col-xl-7 d-flex align-items-center justify-content-center">
                                <label class="badge-danger">Em Trânsito</label>
                            </div>
                        </div>
                        <hr>
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-xl-2">
                                <span class="material-symbols-outlined">
                                    lock
                                </span>
                            </div>
                            <div class="col-xl-2">
                                <h4> {{!!lacreData?.lacresEmTransito ? lacreData?.lacresEmTransito : 0}} </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card pt-3">
                <div>
                    <h3 class="card-title">Próximas Viagens</h3>
                </div>
                <hr>
                <table class="crud-table">
                    <thead>
                        <tr>
                            <th>Viagem</th>
                            <th>Serial</th>
                            <th>Início</th>
                            <th>Chegada</th>
                            <th>status</th>
                            <th>Empresas</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let viagem of listViagens">
                            <td>{{ viagem.nome }}</td>
                            <td>{{ viagem.lacre?.numeroDeSerie }}</td>
                            <td>{{!!viagem.rota ? viagem.rota[0].descricao : '--'}}</td>
                            <td>{{!!viagem.rota ? viagem.rota[1].descricao : '--'}}</td>
                            <td>{{!!viagem.status ? viagem.status.titulo : '--'}}</td>
                            <td>{{!!viagem.empresa.nome ? viagem.empresa?.nome : '--'}}</td>
                            <td>
                                <button mat-button [matMenuTriggerFor]="menu"
                                    [disabled]="!permissao.update && !permissao.delete" class="card-button">
                                    <span class="material-symbols-outlined material-icons">
                                        more_vert
                                    </span>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button *ngIf="permissao.update" mat-menu-item
                                        (click)="editar(viagem.id)">Editar</button>
                                    <button *ngIf="permissao.delete" mat-menu-item
                                        (click)="excluir(viagem)">Excluir</button>
                                    <button *ngIf="viagem.status?.titulo == 'AGENDADA' && permissao.acoes"
                                        (click)="comecarViagem(viagem)" mat-menu-item>Começar Viagem</button>
                                    <button *ngIf="viagem.status?.titulo == 'INICIADA' && permissao.acoes"
                                        (click)="finalizarViagem(viagem)" mat-menu-item>Finalizar Viagem</button>
                                    <button *ngIf="viagem.status?.titulo == 'AGENDADA' && permissao.acoes"
                                        (click)="cancelarViagem(viagem)" mat-menu-item>Cancelar Viagem</button>
                                </mat-menu>
                            </td>
                        </tr>
                        <tr *ngIf="listViagens.length <= 0">
                            <td colspan="12" style="text-align: center; padding-top: 1em; padding-bottom: 1em;">Nenhum
                                registro encontrado</td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-center card-footer">
                    <dg-pagination (changePage)="changePage($event)" [dataPagination]="pagination"></dg-pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>

<!-- <input #relatorioModal hidden data-bs-toggle="modal" data-bs-target="#relatorioModal">
<div class="modal" id="relatorioModal" tabindex="-1" aria-labelledby="relatorioModal" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Baixar Relatórios</h1>
        <button #modalClose type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="row modal-body">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
          <button class="btn btn-outline-danger" (click)="baixarRelatorio(data, 'PDF')">PDF</button>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
          <button class="btn btn-outline-success" (click)="baixarRelatorio(data, 'EXCEL')">Excel</button>
        </div>
      </div>
    </div>
  </div>
</div> -->