import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageFooterComponent } from '../components/page-footer/page-footer.component';
import { DgPaginationComponent } from '../components/dg-pagination/dg-pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
@NgModule({
  declarations: [
    PageFooterComponent,
    DgPaginationComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    DgCrudModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatChipsModule,
    MatSnackBarModule
  ],
  exports: [
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    DgCrudModule,
    RouterModule,
    PageFooterComponent,
    DgPaginationComponent,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatChipsModule,
    MatSnackBarModule
  ]
})
export class SharedModule { }
