import { AbstractEntity, DataServer, DgFilter_input, DgTableColumn } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { EmpresaEntity } from './empresa.entity';
import { formataMask } from 'src/app/helpers/helpers';

@DataServer({
  path: environment.api.URI,
  context: 'motorista'
})
export class MotoristasEntity extends AbstractEntity {

  constructor(
    id?: string | number,
    nome?: string,
    rg?: string,
    cpf?: string,
    telefone?: string | number,
    cnh?: string,
    empresas?: EmpresaEntity[]
  ) {
    super()
    this.id = id;
    this.nome = nome;
    this.rg = rg;
    this.cpf = cpf;
    this.telefone = telefone;
    this.cnh = cnh;
    this.empresas = Array.isArray(empresas) ? empresas : [];
  }

  @DgFilter_input('text', { name: 'Motorista' })
  @DgTableColumn({ columnName: 'Motorista', resource: (val: string) => !!val ? val : '--', sort: true })
  public nome?: string | undefined;

  @DgTableColumn({ columnName: 'RG', resource: (val: string | number) => !!val ? formataMask(val, 'RG') : '--', sort: true })
  public rg?: string | undefined;

  @DgFilter_input('text', { name: 'CPF', mask: "000.000.000-00" })
  @DgTableColumn({ columnName: 'CPF', resource: (val: string) => !!val ? formataMask(val, 'CPF') : '--', sort: true })
  public cpf?: string | undefined;

  @DgTableColumn({ columnName: 'Telefone', resource: (val: string | number) => !!val ? formataMask(val, 'TELEFONE') : '--', sort: true })
  public telefone?: string | number | undefined;

  @DgFilter_input('text', { name: 'CNH', mask: "00000000-00" })
  @DgTableColumn({ columnName: 'CNH', resource: (val: string | number) => !!val ? formataMask(val, 'CNH') : '--', sort: true })
  public cnh?: string | undefined;

  @DgTableColumn({
    columnName: 'Empresas', resource: (val: EmpresaEntity[]) => val.length > 0  ? val.map(v => {return v.nome}) : '--', sort: true
  })
  public empresas?: EmpresaEntity[] | undefined;

}
