import { environment } from './../../../../environments/environment';
import { AbstractEntity, DataServer, DgFilter_input, DgTableColumn } from "@datagrupo/dg-crud";
import { EmpresaEntity } from './empresa.entity';
import { SimCardEntity } from './simcard.entity';

export enum Status {
  DISPONIVEL = 'Disponível',
  ALOCADO = 'Alocado',
  EM_ANDAMENTO = 'Em Andamento'
}

@DataServer({
  path: environment.api.URI,
  context: 'lacre'
})

export class LacresEntity extends AbstractEntity {

  constructor(
    id?: string | number,
    numeroDeSerie?: string | number,
    imei?: string | number,
    bateria?: string,
    status?: Status,
    travado?: boolean,
    violado?: boolean,
    empresa?: EmpresaEntity,
    simCard?: SimCardEntity,
  ) {
    super()
    this.id = id;
    this.numeroDeSerie = numeroDeSerie;
    this.imei = imei;
    this.bateria = bateria;
    this.status = status;
    this.travado = travado;
    this.violado = violado;
    this.empresa = empresa,
      this.simCard = simCard
  }

  @DgFilter_input('number', { name: 'Nº de Série', nameParamFind: 'numeroSerie' })
  @DgTableColumn({ columnName: 'Nº de série', resource: (val: string | number) => !!val ? val : '--', sort: true })
  public numeroDeSerie?: string | number | undefined;


  @DgTableColumn({ columnName: 'Comunicação', resource: (val: string) => !!val ? val : '--', sort: true })
  public comunicacao?: string;

  @DgTableColumn({ columnName: 'Bateria', resource: (val: string) => !!val ? val : '--', sort: true })
  public bateria?: string;

  @DgTableColumn({ columnName: 'Status', resource: (val: any) => !!val ? val.titulo : '--', sort: true })
  public status?: Status

  @DgTableColumn({
    columnName: 'Monitoramento', resource: (val: any, row: LacresEntity) => {
      return `<a href="user/monitoramento/${row.imei}">Monitoramento</a>`
    }, 
    sort: true
  })
  public posicionamentoAtual?: string

  @DgTableColumn({ columnName: 'Estado do travamento', resource: (val: boolean) => !!val ? 'Travado' : 'Aberto', sort: true })
  public travado?: boolean


  public violado?: boolean
  public imei?: string | number | undefined;
  public empresa?: EmpresaEntity;
  public simCard?: SimCardEntity;


}
