import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GenericHttpService } from 'src/app/services/generic-http.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.scss']
})
export class RedefinirSenhaComponent implements OnInit {

  form = new FormGroup({
    novaSenha: new FormControl('', [Validators.required]),
    confirmarSenha: new FormControl('', [Validators.required])
  })

  token: string | undefined

  constructor(
    private router: Router,
    private http: GenericHttpService,
    public route: ActivatedRoute
  ) { }



  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.token = params.token
    })
  }

  voltar() {
    this.router.navigate(['/login'])
  }

  enviarSenha() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if(this.form.value.novaSenha != this.form.value.confirmarSenha){
      Swal.fire({
        title: 'Erro ao tentar editar',
        text: 'As senhas não coincidem, por favor, verifique.',
        icon: 'error',
        timer: 4000,
        confirmButtonColor: 'blue'
      })

      return;
    }

    const entity = {

      token: this.token,
      senha: this.form.value.novaSenha
    }

    this.http.patch('auth/resetarsenha', entity).subscribe(resp => {
      Swal.fire({
        title: 'Senha alterada com sucesso',
        icon: 'success',
        confirmButtonColor: 'blue',
        timer: 4000
      }).then(() => {
        this.voltar()
      })
    })
  }

}
