import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RotasMainComponent } from './rotas-main/rotas-main.component';
import { RotasInserEditComponent } from './rotas-inser-edit/rotas-inser-edit.component';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';
import { SharedModule } from 'src/app/ui/shared/shared.module';

@NgModule({
  declarations: [
    RotasMainComponent,
    RotasInserEditComponent,
  ],
  imports: [
    CommonModule,
    NgxMaskModule,
    RouterModule,
    ReactiveFormsModule,
    DgCrudModule,
    MatSelectModule,
    MatMenuModule,
    DragDropModule,
    MatIconModule,
    MatTooltipModule,
    PrivateLayoutModule,
    SharedModule
  ]
})
export class RotasModule { }
