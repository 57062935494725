import { MatFormFieldModule } from '@angular/material/form-field';
import { PublicModule } from './pages/public/public.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutsModule } from "./layouts/layouts.module";
import { PrivateModule } from "./pages/private/private.module";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { AuthModule } from './pages/auth/auth.module';
import { SessionService } from './core/services/session/session.service';


// config para não impor validação em mascaras NGX-MASK
const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutsModule,
    PrivateModule,
    HttpClientModule,
    RouterModule,
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    PublicModule,
    MatFormFieldModule,
    AuthModule,
    MatSnackBarModule
  ],
  providers: [SessionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
