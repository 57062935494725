import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class CallbackMessageInterceptor implements HttpInterceptor {

  constructor(private _snackBar: MatSnackBar) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => {

      if (error.error?.errorCode) {
        let replace: any;

        replace = { title: error.error.message }

        Swal.fire({
          icon: 'error',
          timer: 10000,
          ...replace
        })

      } else if ((error.status !== 401 || error.status !== 403) && !error.error?.message) {
        this._snackBar.open("Renovando sua chave de acesso!", 'X', {
          duration: 3000
        });
      } else if (error.error.statusCode === 400 || error.error.statusCode === 409 || error.error.statusCode === 401) {
        Swal.fire({
          title: error.error.message[0],
          icon: 'error',
          timer: 10000
        });
      }
      return throwError(error)
    }));
  }
}
