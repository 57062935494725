import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SessionService } from 'src/app/core/services/session/session.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  versaoAtual: string = '0.0.0'
  releaseData: string = 'xx/xxxx'

  email = new FormControl('', [Validators.required, Validators.email])

  listTermo: any;

  termoInfo: boolean = false;
  termoPolitica: boolean = false;

  textoTermo: string = '';

  @ViewChild('fecharModal')fecharModal!: ElementRef;

  public form = new FormGroup({
    login: new FormControl('', [Validators.required]),
    senha: new FormControl('', [Validators.required])
  })


  constructor(
    private http: HttpClient,
    private router: Router,
    private sanitazer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.http.get(environment.api.URI + 'editor-texto').subscribe(resp => {
      this.listTermo = resp;
    })
  }

  verifyLogin() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.http.post(`${environment.api.URI}auth/login`, {
      email: this.form.value['login'],
      senha: this.form.value['senha'],
      // recaptcha: environment.api.recaptcha
    })
      .subscribe((res: any) => {
        window.localStorage.setItem('accessToken', res.token);
        window.localStorage.setItem('refreshToken', res.refreshToken);
        this.router.navigate(['/auth/start-session']);
      }, err => {
        Swal.fire({
          title: 'Erro ao logar',
          text: 'Usuário ou senha inválida',
          icon: 'error',
          confirmButtonColor: 'blue'
        })
      })
  }

  getTermo(termo: 'TERMO 1' | 'TERMO 2') {
    if(this.listTermo?.length <= 0){
      this.fecharModal.nativeElement.click();
      Swal.fire({
        title: 'Documento não encontrado',
        text: 'Por favor entre em contato com a Administração',
        icon: 'warning'
      })
      return
    }
    if (termo == 'TERMO 2') {
      this.termoInfo = true;
      this.termoPolitica = false;
      return;
    }
    if (termo = 'TERMO 1') {
      this.termoPolitica = true;
      this.termoInfo = false;
      return
    }

  }

  ignorSanitize() {
    if (this.listTermo?.length > 0) {
      if (!!this.termoPolitica) {
        return this.sanitazer.bypassSecurityTrustHtml(this.listTermo[0].texto)
      }
      return this.sanitazer.bypassSecurityTrustHtml(this.listTermo[1].texto)
    }
    return
  }

}




