<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-inline-flex align-items-center justify-content-between ">
          <h4>Cadastro de viagens</h4>
          <mat-icon class="fs-5 material-symbols-outlined d-flex"
            matTooltip="Por favor, preencha todos os campos sinalizados com um *" matTooltipPosition="after">
            help
          </mat-icon>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Nome da Viagem</label><small style="color: red;"> *</small>
                <input class="form-control" id="nome" formControlName="nome">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Transportadoras</label><small style="color: red;"> *</small>
                <mat-select class="form-select" formControlName="empresa">
                  <mat-option *ngFor="let emp of listEmpresa" [value]="emp.id">
                    {{emp.nome}}
                  </mat-option>
                </mat-select>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Motoristas</label><small style="color: red;"> *</small>
                <mat-select class="form-select" formControlName="motorista">
                  <mat-option *ngFor="let mot of listMotorista" [value]="mot.id">
                    {{ mot.nome }}
                  </mat-option>
                </mat-select>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Código da viagem</label><small style="color: red;"> *</small>
                <input type="text" id="codigo" formControlName="codigo" class="form-control">
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Caminhão</label><small style="color: red;"> *</small>
                <mat-select class="form-select" formControlName="caminhao">
                  <mat-option *ngFor="let cam of listCaminhoes" [value]="cam.id">
                    {{cam.placa}}
                  </mat-option>
                </mat-select>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Data da viagem</label><small style="color: red;"> *</small>
                <input type="date" formControlName="dataInicio" class="form-control"
                  [min]="currentDate | date: 'yyyy-MM-dd'">
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Lacres eletrônicos</label><small style="color: red;"> *</small>
                <mat-select class="form-select" formControlName="lacre">
                  <mat-option *ngFor="let lac of listLacres" [value]="lac.id">
                    {{lac.numeroDeSerie || '--'}}
                  </mat-option>
                </mat-select>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Rotas</label>
                <mat-select class="form-select" formControlName="rota">
                  <mat-option *ngFor="let rot of listRotas" [value]="rot.id">
                    {{ rot.nome || '--' }}
                  </mat-option>
                </mat-select>
                <div>
                  <div class="d-flex justify-content-end align-items-center pt-2 ">
                    <button class="label-button btn btn-success me-2" #modalParada data-bs-toggle="modal"
                      data-bs-target="#modalParada" [disabled]="!canEdit" (click)="openModalRota()">Criar rota</button>
                    <button class="label-button btn btn-primary" #modalParada data-bs-toggle="modal"
                      data-bs-target="#modalParada" [disabled]="!canEdit" (click)="editarRota(form.value.rota)"
                      [disabled]="!form.value.rota || !canEdit">Alterar rota</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <app-footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
            <button class="btn btn-success" [disabled]="!canEdit" (click)="saveOrUpdateEntity()">Salvar</button>
          </div>
        </app-footer>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modalParada">
  <div class="modal-dialog modal-xl">
    <div class="modal-content" style="z-index: 100;">
      <div class="modal-header">
        <h1 class="modal-title fs-5">Adicione Paradas</h1>
        <button type="button" #closeModal id="closeModal" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formParadas">
          <div class="col-xl-12">
            <label>Nome da Rota</label>
            <input class="form-control" id="nomeRota" [formControl]="nomeRota" required>
          </div>
          <div class="row">
            <div class="col-xl-4">
              <label>Identificação da Parada</label>
              <input class="form-control" id="descricao" formControlName="descricao" required>
            </div>
            <div class="col-xl-2">
              <label>CEP</label>
              <input class="form-control" id="cepParada" mask="00000-000" formControlName="cep" (change)="getCep()"
                required>
            </div>
            <div class="col-xl-6">
              <label>Logradouro</label>
              <input class="form-control" id="logradouroParada" formControlName="logradouro" required>
            </div>
            <div class="col-xl-2">
              <label>Número</label>
              <input class="form-control" id="numeroParada" formControlName="numero">
            </div>
            <div class="col-xl-2">
              <label>Complemento</label>
              <input class="form-control" id="complementoParada" formControlName="complemento">
            </div>
            <div class="col-xl-4">
              <label>Bairro</label>
              <input class="form-control" id="bairroParada" formControlName="bairro" required>
            </div>
            <div class="col-xl-2">
              <label>Cidade</label>
              <input class="form-control" id="localidadeParada" formControlName="cidade" required>
            </div>
            <div class="col-xl-2">
              <label>UF</label>
              <input class="form-control" id="ufParada" formControlName="estado" required>
            </div>
          </div>
        </form>
      </div>
      <div class="pt-3">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Posição</th>
              <th scope="col">Nome da Parada</th>
              <th scope="col">Logradouro</th>
              <th scope="col">Número</th>
              <th scope="col">Complemento</th>
              <th scope="col">Bairro</th>
              <th scope="col">Cidade</th>
              <th scope="col">UF</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <tr class="example-box" *ngFor="let parada of listaParadas; let i = index" cdkDrag>
              <td>{{i + 1 || '--'}}</td>
              <td>{{parada.descricao || '--'}}</td>
              <td>{{parada.logradouro || '--'}}</td>
              <td>{{parada.numero || '--'}}</td>
              <td>{{parada.complemento || '--'}}</td>
              <td>{{parada.bairro || '--'}}</td>
              <td>{{parada.cidade || '--'}}</td>
              <td>{{parada.estado || '--'}}</td>
              <td>
                <button mat-button [matMenuTriggerFor]="menu">
                  <span class="material-symbols-outlined material-icons">
                    more_vert
                  </span>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="editParada(parada, i)">Editar</button>
                  <button mat-menu-item (click)="excluirLinha(i)">Excluir</button>
                </mat-menu>
              </td>
            </tr>
            <tr *ngIf="listaParadas.length <= 0">
              <td colspan="12" style="text-align: center;">Nenhum registro encontrado</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="addParada()">Adicionar parada</button>
        <button type="button" class="btn btn-success" (click)="salveOrUpdateRota()">Salvar rota</button>
      </div>
    </div>
  </div>
</div>