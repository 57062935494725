import { EmpresaEntity } from './empresa.entity';
import { AbstractEntity, DataServer, DgFilter_input, DgTableColumn } from "@datagrupo/dg-crud";
import { environment } from "../../../../environments/environment";
import { formataData } from 'src/app/helpers/helpers';

@DataServer({
  path: environment.api.URI,
  context: 'caminhao'
})
export class CaminhoesEntity extends AbstractEntity {

  constructor(
    id?: string | number,
    marca?: string,
    anoFabricacao?: number,
    anoModelo?: number,
    placa?: string,
    empresas?: EmpresaEntity[]
  ) {
    super()
    this.id = id;
    this.marca = marca;
    this.anoFabricacao = anoFabricacao;
    this.anoModelo = anoModelo;
    this.placa = placa;
    this.empresas = empresas;
  }

  @DgFilter_input('text', { name: 'Marca' })
  @DgTableColumn({ columnName: 'Marca', resource: (val: string) => !!val ? val : '--', sort: true })
  public marca?: string | undefined;


  @DgTableColumn({ columnName: 'Ano de fabricação', resource: (val: string) => !!val ? val : '--', sort: true })
  public anoFabricacao?: number | undefined;


  @DgTableColumn({ columnName: 'Ano do modelo', resource: (val: string) => !!val ? val : '--', sort: true })
  public anoModelo?: number | undefined;

  @DgFilter_input('text', { name: 'Placa' })
  @DgTableColumn({ columnName: 'Placa', resource: (val: string) => !!val ? val : '--', sort: true })
  public placa?: string | undefined;

  @DgFilter_input('text', { name: 'Empresa', nameParamFind: 'empresa' })
  @DgTableColumn({
    columnName: 'Empresa', resource: ((val: EmpresaEntity[]) => {
      return val.length > 0 ? val.map(resp => resp.nome) : '--';
    }), 
    sort: true
  })
  public empresas?: EmpresaEntity[];
}
