import { AbstractEntity, DataServer, DgFilter_input, DgTableColumn } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { EmpresaEntity } from './empresa.entity';
import { EnumList, formataMask } from 'src/app/helpers/helpers';
import { LacresEntity } from './lacres.entity';

@DataServer({
  path: environment.api.URI,
  context: 'simcard'
})
export class SimCardEntity extends AbstractEntity{

  constructor(
    id?: string | number,
    numero?: string | number,
    operadora?: EnumList,
    pin?: string | number,
    empresa?: EmpresaEntity,
    lacre?: LacresEntity
  ){
    super()
    this.id = id;
    this.numero = numero;
    this.operadora = operadora;
    this.pin = pin;
    this.empresa = empresa;
    this.lacre = lacre;
  }

  @DgFilter_input('text',{name: 'Número', mask:'(00)00000-0000'})
  @DgTableColumn({ columnName: 'Número', resource: (val: string | number) => !!val ? formataMask(val, 'TELEFONE') : '--', sort: true })
  public numero?: string | number;

  @DgFilter_input('text', { name: 'Operadora' })
  @DgTableColumn({ columnName: 'Operadora', resource: (val: EnumList) => !!val ? val.titulo : '--', sort: true })
  public operadora?: EnumList;

  @DgTableColumn({ columnName: 'PIN', resource: (val: string | number) => !!val ? val : '--', sort: true})
  public pin?: string | number;

  @DgFilter_input('text', { name: 'Empresa' })
  @DgTableColumn({ columnName: 'Empresa', resource: (val: EmpresaEntity) => {return !!val ? val.nome : '--'}, sort: true  })
  public empresa?: EmpresaEntity;

  public lacre?: LacresEntity

}
