import {AbstractEntity, DataServer, DgFilter_input, DgTableColumn} from "@datagrupo/dg-crud";
import { environment } from "../../../../environments/environment";
import { formataMask } from "src/app/helpers/helpers";

@DataServer({
  path: environment.api.URI,
  context: 'empresa'
})

export class EmpresaEntity extends AbstractEntity {

  constructor(
    id?: number,
    nome?: string,
    email?: string,
    createdAt?: string,
    cnpj?: string,
    emailsAlerta?: string,
    ativo?: boolean
  ) {
    super()
    this.id = id;
    this.nome = nome;
    this.email = email;
    this.createdAt = createdAt;
    this.cnpj = cnpj;
    this.emailsAlerta = emailsAlerta;
    this.ativo = ativo;
  }

  @DgFilter_input('text')
  @DgTableColumn({ columnName: 'Nome', resource: (val: string) => !!val ? val : '--', sort: true })
  public nome?: string | undefined;

  public createdAt?: string | undefined;

  @DgTableColumn({ columnName: 'CNPJ', mask: 'cnpj', resource: (val: string) => !!val ? formataMask(val, 'CNPJ') : '--', sort: true })
  public cnpj?: string | undefined;

  @DgTableColumn({columnName: 'E-mail', resource: (val: string) => !!val ? val : '--', sort: true })
  public email?: string | undefined;

  @DgTableColumn({columnName: 'Status', resource: (status: boolean) =>  status == true ? 'Ativado' : 'Desativado', sort: true})
  public ativo?: boolean | undefined;

  public emailsAlerta?: string | undefined;

}

