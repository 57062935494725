<div class="wrapper">

  <dg-sidebar-menu #sidebar>

    <dg-sidebar-menu-logo img="/assets/images/sety-logo-svg.png"></dg-sidebar-menu-logo>

    <dg-sidebar-menu-navigation-item *ngFor="let item of menu" [startHr]="!!item.hrStart"
      [endHr]="!!item.hrEnd" [listNavigation]="item.menuList">
    </dg-sidebar-menu-navigation-item>
  </dg-sidebar-menu>

  <!-- Page Content  -->
  <div id="content">

    <div id='layout-appRoot'>

      <!--      <navbar-header (changeSidebar)="sidebar.changeState()"></navbar-header>-->
      <navbar-header (changeSidebar)="sidebar.changeState()"></navbar-header>

      <div class="router-outlet-root">
        <router-outlet>
        </router-outlet>



      </div>

    </div>
  </div>
</div>


<input #importModal data-bs-toggle="modal" data-bs-target="#importModal">

<!-- Modal -->
<div class="modal" id="importModal" tabindex="-1" aria-labelledby="importModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Importar dados</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-5">
            <input type="file" #fileChange class="form-control" id="formFile" (change)="onFileChange($event)" hidden>
            <button class="btn btn-success" (click)="fileChange.click()">Escolher Arquivo</button>
          </div>
          <div class="col-7">
            <select class="form-select" [formControl]="identificador">
              <option *ngFor="let m of importList">{{m}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="importarDados()">Importar dados</button>
      </div>
    </div>
  </div>
</div>
