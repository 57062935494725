<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-inline-flex align-items-center justify-content-between ">
          <h4>Cadastro de SimCard</h4>
          <mat-icon class="fs-5 material-symbols-outlined d-flex"
            matTooltip="Por favor, preencha todos os campos sinalizados com um *" matTooltipPosition="after">
            help
          </mat-icon>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Número</label><small style="color: red;"> *</small>
                <input name="text" formControlName="numero" minlength="11" type="text" class="form-control"
                  mask="(00) 00000-0000">
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Operadora</label><small style="color: red;"> *</small>
                <mat-select matNativeControl required class="form-select" formControlName="operadoraId">
                  <mat-option [value]="op?.id" *ngFor="let op of enumsList" >{{ op?.titulo }}</mat-option>
                </mat-select>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Empresas</label>
                <mat-select class="form-select" formControlName="empresa">
                  <mat-option *ngFor="let emp of empresas" [value]="emp.id">
                    {{emp.nome}}
                  </mat-option>
                </mat-select>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">PIN</label><small style="color: red;"> *</small>
                <input name="pin" formControlName="pin" maxlength="4" type="text" class="form-control">
              </div>
            </div>
          </form>
        </div>
        <app-footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
            <button class="btn btn-success" (click)="saveOrUpdateEntity()">Salvar</button>
          </div>
        </app-footer>
      </div>
    </div>
  </div>
</div>