import { Component, OnInit } from '@angular/core';
import { DgSidebarMenuComponent } from '@datagrupo/dg-ng-util';

@Component({
  selector: 'page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {

  urlMinhaColeta = '';

  public sidebaOpened2 = true

  constructor() {
    this.urlMinhaColeta = window.location.origin
  }

  ngOnInit(): void {
    document.getElementsByClassName('router-outlet-root')[0].classList.add('pagind-footer');
  }

  sidebaOpened() {
    return DgSidebarMenuComponent.open
  }
}
