import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { InterfMenuList, menuItens } from 'src/app/layouts/private-layout/private-layout/private-layout.component';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menu: any[] = [];
  permissao: any[] = [];
  url: any;
  idParams: any;
  constructor(
    private session: SessionService,
    private route: Router,
    public uri: ActivatedRoute
  ) {
    this.menu = JSON.parse(String(window.localStorage.getItem('menu')))
    if(this.menu != undefined){
      this.menu.push({ url: '/auth/start-session', nome: 'start-session', icon: '' });
    }
  }

  redirect() {
    this.getPermissoes();
    this.route.events.subscribe((params: any) => {
      if (params instanceof NavigationEnd) {
        this.permissao.filter((v) => {
          if (!v.read) {
            menuItens[0].menuList.filter(val => {
              if (val.nome == v.identificador) {
                if (val.url == this.route.url) {
                  Swal.fire({
                    title: 'Acesso Negado',
                    text: 'Você não tem autorização para acessar esse conteúdo. (Entre em contato com o seu Administrador)',
                    icon: 'error',
                    timer: 5000,
                    confirmButtonColor: 'blue'
                  })
                  this.route.navigate([`${this.menu[0].url}`])
                }
              }
            })
          }
        })
        if (this.route.url.includes('/novo')) {
          const urlOutNovo = this.route.url.replace('/novo', '');
          const a = menuItens[0].menuList.find(v => v.url == urlOutNovo);
          const b = this.session.user.permissoes?.find(val => val.identificador?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') == a?.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
          if (!b?.create) {
            Swal.fire({
              title: 'Acesso Negado',
              text: 'Você não tem autorização para acessar esse conteúdo. (Entre em contato com o seu Administrador)',
              icon: 'error',
              timer: 5000,
              confirmButtonColor: 'blue'
            })
            this.route.navigate([`${this.menu[0].url}`])
          }
        }
        const numUrl = this.route.url.match(/[0-9]/g)?.join('');
        if (numUrl) {
          const endUrl = numUrl
          if (this.route.url.includes(endUrl)) {
            const a = menuItens[0].menuList.find(v => v.url == this.route.url.replace('/' + endUrl, ''));
            const b = this.session.user.permissoes?.find(val => val.identificador?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') == a?.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
            if (!isNaN(Number(endUrl))) {
              if (a?.url == '/user/usuarios') {
                if (!b?.update && (this.session.user.id == Number(endUrl))) {
                  return
                }
              }
              if (a?.url == '/user/monitoramento' && b?.read) {
                return
              }
              if (!b?.update) {
                Swal.fire({
                  title: 'Acesso Negado',
                  text: 'Você não tem autorização para acessar esse conteúdo. (Entre em contato com o seu Administrador)',
                  icon: 'error',
                  timer: 5000,
                  confirmButtonColor: 'blue'
                })
                this.route.navigate([`${this.menu[0].url}`])
              }
            }

          }
        }
        if (params.url == '/user') {
          this.route.navigate([`${this.menu[0].url}`])
        }
      }
    })
  }

  getPermissoes() {
    if (this.session.user.permissoes) {
      this.session.user.permissoes.filter(v => {
        if (!v.read) {
          this.permissao.push(v)
        }
      });
    }
  }
}
