<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-header row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <h4 class="card-title">Permissões</h4>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-inline-flex justify-content-sm-center justify-content-end">
          <button [disabled]="!permissao.create" class="btn btn-success" routerLink="novo">Adicionar permissão</button>
        </div>
      </div>
      <div class="card-body">

        <crud-data-table 
        #table 
        [entityMap]="mapEntity" 
        tableEventId="permissoes"
        [resourceDictionary]="dictionary"
        [customActions]="customActions"
        >
        </crud-data-table>
      </div>
      <div class="d-flex justify-content-center card-footer">
        <dg-pagination [dataPagination]="mapEntity.configEntityTable.pagination"
          (changePage)="table.changePages($event)"></dg-pagination>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>