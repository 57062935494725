import { MatSelectModule } from '@angular/material/select';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { LacresMainComponent } from './lacres-main/lacres-main.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacresInserEditComponent } from './lacres-inser-edit/lacres-inser-edit.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';
import { SharedModule } from 'src/app/ui/shared/shared.module';
import { CdkDynamicGroupModule, CdkDynamicTableModule, DgPaginatorModule, DgTableModule } from '@datagrupo/dg-ng-util';




@NgModule({
  declarations: [
    LacresMainComponent,
    LacresInserEditComponent,
  ],
  imports: [
    CommonModule,
    NgxMaskModule,
    RouterModule,
    ReactiveFormsModule,
    DgCrudModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    PrivateLayoutModule,
    SharedModule,
    DgTableModule,
    DgPaginatorModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule
  ]
})
export class LacresModule { }
