<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-inline-flex align-items-center justify-content-between">
          <h4>Cadastro de rotas</h4>
          <mat-icon class="fs-5 material-symbols-outlined d-flex"
            matTooltip="Por favor, preencha todos os campos sinalizados com um *" matTooltipPosition="after">
            help
          </mat-icon>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xl-8">
              <form [formGroup]="form">
                <label>Nome da Rota</label><small style="color: red;"> *</small>
                <input type="text" class="form-control" formControlName="nomeViagem">
              </form>
            </div>
            <div class="col-xl-4 d-flex align-items-center" style="padding-top: 1.5em;">
              
            </div>

            <div class="pt-3 crud-table">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Posição</th>
                    <th scope="col">Nome da Parada</th>
                    <th scope="col">Logradouro</th>
                    <th scope="col">Número</th>
                    <th scope="col">Complemento</th>
                    <th scope="col">Bairro</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">UF</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                  <tr class="example-box" *ngFor="let parada of listaParadas; let i = index" cdkDrag>
                    <td>{{i + 1 || '--'}}</td>
                    <td>{{parada.descricao || '--'}}</td>
                    <td>{{parada.logradouro || '--'}}</td>
                    <td>{{parada.numero || '--'}}</td>
                    <td>{{parada.complemento || '--'}}</td>
                    <td>{{parada.bairro || '--'}}</td>
                    <td>{{parada.cidade || '--'}}</td>
                    <td>{{parada.estado || '--'}}</td>
                    <td>
                      <button mat-button [matMenuTriggerFor]="menu">
                        <span class="material-symbols-outlined material-icons">
                          more_vert
                        </span>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editLinha(parada, i)">Editar</button>
                        <button mat-menu-item (click)="excluirLinha(i)">Excluir</button>
                      </mat-menu>
                    </td>
                  </tr>
                  <tr *ngIf="listaParadas.length <= 0">
                    <td colspan="12" style="text-align: center;">Nenhum registro encontrado</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <app-footer>
              <div class="d-flex justify-content-end">
                <button type="button" #modalParada class="btn btn-primary" data-bs-toggle="modal"
                data-bs-target="#modalParada" (click)="open()">Adicionar Parada</button>
                <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
                <button class="btn btn-success" (click)="saveOfUpdateEntity()">Salvar</button>
              </div>
            </app-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modalParada" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5">Adicione Paradas</h1>
        <button type="button" #closeModal id="closeModal" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formParadas">
          <div class="row">
            <div class="col-xl-4">
              <label>Nome da Parada</label><small style="color: red;"> *</small>
              <input class="form-control" id="descricao" formControlName="descricao" required>
            </div>
            <div class="col-xl-2">
              <label>CEP</label><small style="color: red;"> *</small>
              <input class="form-control" id="cepParada" mask="00000-000" formControlName="cep" (focusout)="getCep()"
                required>
            </div>
            <div class="col-xl-6">
              <label>Logradouro</label>
              <input class="form-control" id="logradouroParada" formControlName="logradouro" required>
            </div>
            <div class="col-xl-2">
              <label>Número</label>
              <input class="form-control" id="numeroParada" formControlName="numero">
            </div>
            <div class="col-xl-2">
              <label>Complemento</label>
              <input class="form-control" id="complementoParada" formControlName="complemento">
            </div>
            <div class="col-xl-2">
              <label>Bairro</label>
              <input class="form-control" id="bairroParada" formControlName="bairro" required>
            </div>
            <div class="col-xl-3">
              <label>Cidade</label>
              <input class="form-control" id="localidadeParada" formControlName="cidade" required>
            </div>
            <div class="col-xl-2">
              <label>UF</label>
              <input class="form-control" id="ufParada" formControlName="estado" required>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-success" (click)="addParada()">Adicionar parada</button>
      </div>
    </div>
  </div>
</div>