import { environment } from './../../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LacresEntity } from './../../entitys/lacres.entity';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InterfaceCustomActions, crudDispatchEvent } from "@datagrupo/dg-crud";
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session/session.service';
import { CdkDynamicTable, CdkDynamicTableService } from '@datagrupo/dg-ng-util';
import { table } from 'console';


@Component({
  selector: 'app-lacres-main',
  templateUrl: './lacres-main.component.html',
  styleUrls: ['./lacres-main.component.scss']
})
export class LacresMainComponent implements OnInit, OnDestroy {

  public mapEntity = new LacresEntity()

  public tableCustom: CdkDynamicTable.tableClass;

  customActions: InterfaceCustomActions[] = [];

  public numSerie: any;

  public permissao: any;

  private timeout: NodeJS.Timeout|string|number|null=null

  constructor(
    public http: HttpClient,
    private route: Router,
    private session: SessionService,
    private createTable: CdkDynamicTableService
  ) {
    this.permissao = session.user.permissoes?.find(v => v.identificador?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() == 'lacres eletronicos')
    if (this.permissao?.read) {
      this.customActions.push(
        {
          name: 'Editar',
          action: !!this.permissao.update ? (row: LacresEntity) => this.navigate(row.id) : () => { },
          permission: !!this.permissao.update ? true : false
        },
        {
          name: 'Excluir',
          action: !!this.permissao.delete ? (row: LacresEntity) => this.delete(row) : () => { },
          permission: !!this.permissao.delete ? true : false
        },
      )
    }
    this.tableCustom = createTable.create('request', {
      apiData: {
        path: environment.api.URI,
        context: 'lacre'
      },
      columns: [
        { headerName: 'Nº de Série', name: 'numeroDeSerie', },
        { headerName: 'Comunicação', name: 'comunicacao' },
        { headerName: 'Bateria', name: 'bateria' },
        { headerName: 'Status', name: 'status' },
        { headerName: 'Monitoramento', name: 'posicionamentoAtual' },
        { headerName: 'Estado do travamento', name: 'travado' },
        { headerName: 'Empresas', name: 'empresa'}
      ],
      sort: ['numeroDeSerie', 'comunicacao', 'bateria', 'status', 'posicionamentoAtual', 'travado', 'empresa'],
      actions: {
        editar: { name: 'Editar', action: (row: LacresEntity) => this.navigate(row.id), dbClick: true, permission: () => !!this.permissao.update ? true : false },
        deletar: { name: 'Deletar', action: (row: LacresEntity) => this.delete(row), permission: () =>  !!this.permissao.delete ? true : false }
      },
      filters: {
        group: 'lacre', reactive: false, filters: {
          numeroDeSerie: {
            findFunc: (val: any) => {
              return {numeroSerie: val}
            }
          }
        }
      }
    });
  }
  ngOnDestroy(): void {
    if(this.timeout){
      clearInterval(this.timeout);
    }
  }


  ngOnInit(): void { 
    this.timeout = setInterval(() =>this.tableCustom.find(), 60_000);
  }

  dictionary = (data: any) => {
    return data;
  };

  navigate(index: string | number | undefined) {
    this.route.navigate([`/user/lacres/${index}`])
  }

  delete(row: LacresEntity) {
    Swal.fire({
      title: 'Excluir registro',
      text: 'Deseja excluir esse registro? Esta ação é permanente.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        this.http.delete(environment.api.URI + `lacre/${row.id}`).subscribe(resp => {
          Swal.fire({
            title: 'Deletado com sucesso',
            text: 'Registro deletado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          });
          crudDispatchEvent('lacre')
        },
          (error: HttpErrorResponse) => {
            if (error.status === 400) {
              Swal.fire({
                title: 'Erro',
                text: 'Dados vinculados não podem ser excluídos',
                icon: 'error',
                showConfirmButton: true

              });
            }

          }
        );
      }
    });
  }

}