import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionGuard } from './core/guards/session/session.guard';
import { PrivateLayoutComponent } from "./layouts/private-layout/private-layout/private-layout.component";
import { LoginComponent } from "./pages/public/login/login.component";
import { StartSessionComponent } from './pages/auth/start-session/start-session.component';
import { EsqueceuSenhaComponent } from './pages/public/esqueceu-senha/esqueceu-senha.component';
import { RedefinirSenhaComponent } from './pages/public/redefinir-senha/redefinir-senha.component';

const routes: Routes = [
  {
    path: 'user',
    component: PrivateLayoutComponent,
    loadChildren: () =>
      import('./pages/private/private-routing.module')
        .then((m) => m.PrivateRoutingModule),
    canActivate: [SessionGuard]
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'auth/start-session',
    component: StartSessionComponent,
  },
  {
    path: 'esqueceu-senha',
    component: EsqueceuSenhaComponent
  },
  {
    path: 'redefinir-senha',
    component: RedefinirSenhaComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
