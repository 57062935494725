import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { MatMenuModule } from '@angular/material/menu';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';
import { SharedModule } from 'src/app/ui/shared/shared.module';


@NgModule({
  declarations: [
    DashboardMainComponent
  ],
  imports: [
    CommonModule,
    NgxMaskModule,
    RouterModule,
    ReactiveFormsModule,
    DgCrudModule,
    MatSelectModule,
    MatMenuModule,
    PrivateLayoutModule,
    SharedModule
  ]
})
export class DashboardModule { }
