<div class="page-footer" [class.sidebar-opened]="!sidebaOpened()">
  <div class="conteiner h-100">
    <div class="row h-100">

      <div class="col-lg-8 col-md-12  copyright">
        Todos os Direitos Reservados: &nbsp; <a href="https://setytecnologia.com.br/" target="_blank"> Sety ©</a>.
        Desenvolvido por &nbsp; <a href="http://www.datagrupo.com.br" target="_blank"> DATAGRUPO</a>.
      </div>
      <div class="col-lg-4 col-md-12  row-btn">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>