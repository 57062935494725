import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrivateLayoutComponent} from './private-layout/private-layout.component';
import {RouterModule} from "@angular/router";
import { NavbarHeaderComponent } from './components/navbar-header/navbar-header.component';
import {DgAutocompleteModule, DgSidebarMenuModule} from "@datagrupo/dg-ng-util";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import { FooterComponent } from './components/footer/footer.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SharedModule } from 'src/app/ui/shared/shared.module';



@NgModule({
  declarations: [
    PrivateLayoutComponent,
    NavbarHeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DgSidebarMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    DgAutocompleteModule,
    AngularEditorModule,
  ],
  exports:[
    FooterComponent
  ]
})
export class PrivateLayoutModule {
}
