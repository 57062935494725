import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { EmpresaEntity } from "../../../../pages/private/entitys/empresa.entity";
import { GenericHttpService } from "../../../../services/generic-http.service";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { SessionService } from 'src/app/core/services/session/session.service';
import { Router } from '@angular/router';
import { DefaultKolkovTextEditor } from 'src/app/core/config/default-kolkov-text-editor';

export interface interfEditor {
  id?: string | number,
  texto?: string
}

@Component({
  selector: 'navbar-header',
  templateUrl: './navbar-header.component.html',
  styleUrls: ['./navbar-header.component.scss']
})
export class NavbarHeaderComponent implements OnInit {

  empresas: EmpresaEntity[] = [];

  infoLegais: boolean = false;
  poliPrivacidade: boolean = false;

  showOldPassword: boolean = false
  showPassword: boolean = false
  showPassword2: boolean = false

  listTermos: any;

  editorConfig = DefaultKolkovTextEditor;

  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('modalTermo') modalTermo!: ElementRef;

  @ViewChild('modalTermo') modalTermos!: any;

  texto = new FormControl('', Validators.required);

  data: interfEditor | undefined;

  form = new FormGroup({
    senhaAntiga: new FormControl('', Validators.required),
    senha: new FormControl('', Validators.required),
    confirmaSenha: new FormControl('', Validators.required)
  })

  perfilUser: string | undefined;

  perfil: string | undefined;

  @Output('changeSidebar') changeSidebar = new EventEmitter()

  constructor(
    private service: GenericHttpService,
    private session: SessionService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.perfilUser = `usuarios/${this.session.user.id}`;
    this.perfil = this.session.user.nomePermissao;

    console.log(this.perfil)
  }


  fecharModal(identificador: 'SENHA' | 'TERMO') {
    if (identificador == 'SENHA') {
      this.closeModal.nativeElement.click()
      this.form.reset('')
    }
    if (identificador == 'TERMO') {
      this.texto.reset('');
      this.modalTermo.nativeElement.click();
    }
  }

  atualizaSenha() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.form.value.senha !== this.form.value.confirmaSenha) {
      Swal.fire({
        title: 'Erro ao editar',
        text: 'As senhas não coincidem, por favor verifique.',
        icon: 'error',
        timer: 4000,
        confirmButtonColor: 'blue'
      })
      return;
    }


    const entity = {
      senha: this.form.value.senha,
      senhaAntiga: this.form.value.senhaAntiga
    }

    this.service.patch(`usuario/${this.session.user.id}`, entity).subscribe(resp => {
      Swal.fire({
        title: 'Sucesso',
        text: 'Senha alterada com sucesso',
        icon: 'success',
        timer: 5000,
        confirmButtonColor: 'blue'
      })
      this.closeModal.nativeElement.click()
    },
      (error) => {
        if (error.error.error == 'INVALID_OLD_PWD') {
          Swal.fire({
            title: 'Erro ao editar',
            text: 'Verifique sua senha antiga.',
            icon: 'error',
            confirmButtonColor: 'blue'
          })
        } else {
          Swal.fire({
            title: 'Erro ao editar',
            text: 'Ocorreu um erro ao editar o registro, por favor tente novamente mais tarde.',
            icon: 'error',
            confirmButtonColor: 'blue'
          })
        }
        this.form.reset('');
        this.closeModal.nativeElement.click();
      })
  }

  @HostListener('window:open-modal-dados')
  baixarExcel() {
    let link = document.createElement('a');
    link.href = 'assets/docs/Modelo Excel Sety.xlsx';
    link.download = 'Modelo Excel Sety.xlsx';
    link.click();
  }

  logout() {
    this.session.logout()
  }

  openTermoModal() {
    this.texto.reset('');
    this.data = {id: undefined, texto: undefined}
    this.service.get('editor-texto').subscribe(resp => {
      this.listTermos = resp
    })
  }

  getTermo(termo: 'INFO' | 'POLITICA') {
    let politica = this.listTermos.find((v: any) => v.id == 2);
    let infoTermo = this.listTermos.find((v: any) => v.id == 1);
    if (termo == 'INFO') {
      this.texto.patchValue(infoTermo.texto);
      this.data = {
        id: infoTermo.id,
        texto: this.texto.value
      }
    }
    if (termo == 'POLITICA') {
      this.texto.patchValue(politica.texto)
      this.data = {
        id: politica.id,
        texto: this.texto.value
      }
    }
  }

  salvarTermos() {
    
    if (this.texto.invalid) {
      this.texto.markAllAsTouched();
      return
    }
    if (!this.data?.id) {
      this.data = {
        id: undefined,
        texto: this.texto.value
      }
      this.service.post('editor-texto', this.data).subscribe(resp => {
        Swal.fire({
          title: 'Dados inseridos com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.fecharModal('TERMO')
        })
      })
    } else {
      this.data = {
        id: this.data.id,
        texto: this.texto.value
      }
      console.log(this.data)
      this.service.patch('editor-texto/' + this.data.id, this.data).subscribe(resp => {
        Swal.fire({
          title: 'Dados editados com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.fecharModal('TERMO')
        })
      })
    }

  }

}
