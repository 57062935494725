import { GenericHttpService } from './../../../../services/generic-http.service';
import { EmpresaEntity } from './../../entitys/empresa.entity';
import { FormGroup, FormControl, Validators, MaxLengthValidator } from '@angular/forms';
import { LacresEntity } from './../../entitys/lacres.entity';
import { AbstractInsertEdit, InsertEditConfig } from '@datagrupo/dg-crud';
import { Component, OnInit } from '@angular/core';
import { SimCardEntity } from '../../entitys/simcard.entity';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lacres-inser-edit',
  templateUrl: './lacres-inser-edit.component.html',
  styleUrls: ['./lacres-inser-edit.component.scss']
})
export class LacresInserEditComponent extends AbstractInsertEdit<LacresEntity> implements OnInit {

  empresas: EmpresaEntity[] = [];

  listSimCard: SimCardEntity[] = [];

  public form = new FormGroup({
    numeroDeSerie: new FormControl('', [Validators.maxLength(15)]),
    imei: new FormControl(''),
    empresa: new FormControl(''),
    simCard: new FormControl('')
  })

  constructor(
    public config: InsertEditConfig,
    private service: GenericHttpService,
  ) {
    super(config, { path: environment.api.URI, context: 'lacre' })
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.service.get('empresa').subscribe(resp => {
      this.empresas = resp.data;
    });
    this.service.get('simcard').subscribe(resp => {
      this.listSimCard = resp.data;
    })
  }

  initNewEntity(): void {
    this.entity = new LacresEntity()
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
      simCard: this.entity.simCard?.id,
      empresa: this.entity.empresa?.id
    })
  }

  saveOrUpdateEntity() {
   

    const { empresa, simCard, numeroDeSerie, imei, ...form } = this.form.value;

    if (!!form.empresa) {
      const indexEmpresa = this.empresas.findIndex(e => e.id == form.empresa)
      if (indexEmpresa == -1) {
        return;
      }
    }

    if (!!form.simCard) {
      const indexSimcard = this.listSimCard.findIndex(sim => sim.id == form.simCard)
      if (indexSimcard == -1) {
        return;
      }
    }

    this.entity = {
      ...this.entity,
      id: !!this.entityId ? this.entityId : undefined,
      empresa: empresa != '' ? empresa: null,
      numeroDeSerie: !!numeroDeSerie ? numeroDeSerie: undefined,
      imei: !!imei ? imei: undefined,
      simCard: !!simCard ? simCard: undefined,
      ...form,
    }

    if (this.entity.id != undefined) {
      this.service.patch(`lacre/${this.entity.id}`, this.entity).subscribe(() => {
        Swal.fire({
          title: 'Lacre editado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
      )
    } else {
      this.service.post('lacre', this.entity).subscribe(() => {
        Swal.fire({
          title: 'Lacre cadastrado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
        )
    }

  }

}
