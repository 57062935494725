import { Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AbstractAddToken } from '@datagrupo/dg-ng-util';
import { TokenService } from '../../services/token.service';
import { environment } from 'src/environments/environment';
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { SessionService } from '../../services/session/session.service';

@Injectable()
export class AddTokenInterceptor extends AbstractAddToken implements HttpInterceptor {

  constructor(
    private token: TokenService,
    private session: SessionService
  ) {
    super(token)
    this.listUrlExcepAddToken.push(environment.api.URI + 'auth/token', environment.api.URI + 'auth/refresh')
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.rootIntercept(req, next)
  }

  pipeRequestRefreshToken(resp: any) {
    return resp.token
  }
  public override rootIntercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = request;
    const token = this._token.getToken();

    const urlPesquisaAnonima = window.location.pathname.split('/');
    urlPesquisaAnonima.pop();

    if (this.exceptRouter() || this.exceptUrl(request.url)) {
      return next.handle(request)
    }

    if (!request.headers.get(this.name_headerToken)) {
      request = request.clone({
        setHeaders: { [this.name_headerToken]: (this.addBearer ? this.bearer : '') + token }
      });
    }

    // @ts-ignore
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
          if (!this._token.checkExp()) {
            return this.handle401Error(authReq, next);
          }
        }

        return throwError(error);
      })
    );

  }

  public override handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      // iniciando processo de renovação de token
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // buscando token atual
      const token = this._token.getToken();


      // renovar token somente caso o mesmo existe
      if (token)
        return this._token.refreshToken().pipe(
          switchMap((resp: any) => {
            this.isRefreshing = false;

            const token = this.pipeRequestRefreshToken(resp)

            this._token.saveToken(token);
            this.refreshTokenSubject.next(token);
            return next.handle(
              AbstractAddToken.addTokenHeader(request, (this.addBearer ? this.bearer : '') + token, this.name_headerToken)
            );
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.session.logout()
            return throwError(err);
          })
        );
    }

    // após renovação do token, é retornado o requeste para uma nova tentativa
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(
        AbstractAddToken.addTokenHeader(request, (this.addBearer ? this.bearer : '') + token, this.name_headerToken))
      )
    );
  }

}
