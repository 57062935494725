import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RotasEntity } from '../../entitys/rotas.entity';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InterfaceCustomActions, crudDispatchEvent } from '@datagrupo/dg-crud';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session/session.service';

@Component({
  selector: 'app-rotas-main',
  templateUrl: './rotas-main.component.html',
  styleUrls: ['./rotas-main.component.scss'],
})
export class RotasMainComponent implements OnInit {

  public mapEntity = new RotasEntity();

  customActions: InterfaceCustomActions[] = []

  public permissao: any;

  constructor(
    public http: HttpClient,
    private route: Router,
    private session: SessionService
  ) {
    this.permissao = session.user.permissoes?.find(v => v.identificador?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() == 'rotas')
    if (this.permissao?.read) {
      this.customActions.push(
        {
          name: 'Editar',
          action: !!this.permissao.update ? (row: RotasEntity) => this.navigate(row.id) : () => { },
          permission: !!this.permissao.update ? true : false
        },
        {
          name: 'Excluir',
          action: !!this.permissao.delete ? (row: RotasEntity) => this.delete(row) : () => { },
          permission: !!this.permissao.delete ? true : false
        },
      )
    }
  }

  ngOnInit(): void { }

  dictionary = (data: any) => {
    return data;
  };

  navigate(index: string | number | undefined) {
    this.route.navigate([`/user/rotas/${index}`])
  }

  delete(row: RotasEntity) {
    Swal.fire({
      title: 'Excluir registro',
      text: 'Deseja excluir esse registro? Esta ação é permanente.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        this.http.delete(environment.api.URI + `rota/${row.id}`).subscribe(resp => {
          Swal.fire({
            title: 'Deletado com sucesso',
            text: 'Registro deletado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          crudDispatchEvent('rota')
        })
      }

    })
  }
}
