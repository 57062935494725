import { Injectable } from '@angular/core';
import { AbstractHttpService } from "@datagrupo/dg-crud";
import { ConfigDgCrudService } from "../core/config/config-dg-crud/config-dg-crud.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GenericHttpService extends AbstractHttpService<any> {

  constructor(
    public conf: ConfigDgCrudService
  ) {
    super(conf, environment.api.URI);
  }
}
