import { AbstractEntity, DataServer, DgFilter_input, DgTableColumn } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';
import { EmpresaEntity } from './empresa.entity';
import { formataMask } from 'src/app/helpers/helpers';
import { PermissoesEntity } from './permissoes.entity';

@DataServer({
  path: environment.api.URI,
  context: 'usuario'
})
export class UsuarioEntity extends AbstractEntity {

  constructor(
    id?: string | number,
    nome?: string,
    empresa?: EmpresaEntity,
    email?: string,
    telefone?: string | number,
    cargo?: string,
    permissao?: PermissoesEntity
  ) {
    super()
    this.id = id;
    this.nome = nome;
    this.empresa = empresa;
    this.email = email;
    this.telefone = telefone;
    this.cargo = cargo;
    this.permissao = permissao
  }

  @DgFilter_input('text')
  @DgTableColumn({ columnName: 'Nome', resource: (val: string) => !!val ? val : '--', sort: true })
  public nome?: string | undefined;

  @DgTableColumn({ columnName: 'E-mail', resource: (val: string) => !!val ? val : '--', sort: true }) /* precisa de mascara? */
  public email?: string | undefined;

  @DgTableColumn({ columnName: 'Telefone', resource: (val: string | number) => !!val ? formataMask(val, 'TELEFONE') : '--', sort: true })
  public telefone?: string | number | undefined;

  @DgTableColumn({ columnName: 'Cargo', resource: (val: string) => !!val ? val : '--', sort: true })
  public cargo?: string | undefined;

  @DgFilter_input('text')
  @DgTableColumn({
    columnName: 'Empresa', resource: (val: EmpresaEntity) => !!val ? val.nome : '--', sort: true
  })
  public empresa?: EmpresaEntity | undefined;

  public permissao?: PermissoesEntity | undefined;

}
