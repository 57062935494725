import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@datagrupo/dg-crud';
import { MenuListSidebarMenu } from "@datagrupo/dg-ng-util";
import { SessionService } from 'src/app/core/services/session/session.service';
import { LacreAlarmDTO } from 'src/app/pages/private/monitoramento/monitoramento-main/monitoramento-main.types';
import { MonitoramentoService } from 'src/app/pages/private/monitoramento/service/monitoramento.service';
import Swal from 'sweetalert2';

export interface InterfMenuList {
  hrStart?: boolean,
  hrEnd?: boolean,
  menuList: MenuListSidebarMenu[],
}

export const menuItens: InterfMenuList[] = [
  {
    hrStart: true,
    hrEnd: true,
    menuList: [
      {
        url: '/user/dashboard',
        nome: 'Dashboard',
        icon: 'dashboard'
      },
      {
        url: '/user/empresas',
        nome: 'Empresas',
        icon: 'apartment'
      },
      {
        url: '/user/usuarios',
        nome: 'Usuários',
        icon: 'person'
      },
      {
        url: '/user/motoristas',
        nome: 'Motoristas',
        icon: 'badge'
      },
      {
        url: '/user/caminhoes',
        nome: 'Caminhões',
        icon: 'local_shipping'
      },
      {
        url: '/user/simcard',
        nome: 'SimCard',
        icon: 'sim_card'
      },
      {
        url: '/user/lacres',
        nome: 'Lacres Eletrônicos',
        icon: 'lock'
      },
      {
        url: '/user/rotas',
        nome: 'Rotas',
        icon: 'alt_route'
      },
      {
        url: '/user/viagens',
        nome: 'Viagens',
        icon: 'explore'
      },
      {
        url: '/user/monitoramento',
        nome: 'Monitoramento',
        icon: 'map'
      },
      {
        url: '/user/permissoes',
        nome: 'Permissões',
        icon: 'admin_panel_settings',
      },
      // {
      //   url: '/user/logs',
      //   nome: 'Logs do Sistema',
      //   icon: 'flag'
      // },
      // {
      //   nome: 'Gerenciar Dados',
      //   icon: 'storage',
      //   subMenu: [
      //     {
      //       nome: 'Exportar Planilha Cadastro',
      //       icon: 'description',
      //       event: 'open-modal-dados'
      //     },
      //     {
      //       nome: 'Importar dados',
      //       icon: 'upload_file',
      //       event: 'open-import-modal'
      //     }
      //   ]
      // },
      {
        url: '/user/contatos',
        nome: 'Contatos',
        icon: 'call'
      },
    ]
  },
];

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss']
})
export class PrivateLayoutComponent implements OnInit, OnDestroy {

  @ViewChild('importModal') importModal!: ElementRef

  @ViewChild('modalGerenciarDados') modalGerenciarDados!: ElementRef

  importList: string[] = ['Todos', 'Empresas', 'Usuarios', 'Motoristas', 'Caminhoes', 'Simcard', 'Lacres', 'Rotas', 'Viagens', 'Permissoes'];

  identificador = new FormControl('Todos', Validators.required);

  file: any;
  listPermissoes: any[] = [];

  menuList = menuItens
  menu: InterfMenuList[] = [{ hrStart: true, hrEnd: true, menuList: [] }];

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private webSocketService:MonitoramentoService
  ) {
  }
  ngOnDestroy(): void {
    console.log("🚀 ~ PrivateLayoutComponent ~ ngOnDestroy ~ ngOnDestroy:", 'ngOnDestroy')
    
   this.webSocketService.disconect();
  }

  ngOnInit(): void {
    this.setMenu()
    this.webSocketService.connect(this.session.token.getToken())
    this.checkAlarm();

  }
// 
  @HostListener('window: open-import-modal')
  openImportModal() {
    this.importModal.nativeElement.click()
  }

  baixarModelo() {
    window.dispatchEvent(new CustomEvent('open-modal-dados'))
  }

  onFileChange(e: any) {
    this.file = e.target.files[0];
  }

  importarDados() {
    const identificador = this.identificador.value.toUpperCase()
  }

  setMenu() {
    let m = JSON.parse(String(window.localStorage.getItem('menu')));
    this.menu[0].menuList = m
  }

  checkAlarm() {
    this.webSocketService.socket.on('global:lacreAlarm', (resp: LacreAlarmDTO) => {
      if (!!resp.info.isLockNormalOrTampered || !!resp.info.isShacklePulledOut) {
        this.messageViolation(resp.id);
      } else if (!!resp.info.isMovingWhileOpen) {
        Swal.fire({
          title: `ATENÇÃO VERIFIQUE O LACRE ${resp.id}`,
          text: `Caminhão em movimento, o lacre ainda está destravado`,
          icon: 'error',
          confirmButtonColor: 'red'
        })
      }
    })
  }

  messageViolation(imei: string) {
    Swal.fire({
      title: `ATENÇÃO VERIFIQUE O LACRE ${imei}`,
      text: `O lacre está sendo violado`,
      icon: 'error',
      confirmButtonColor: 'red'
    })
  }

}
