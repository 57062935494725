import { GenericHttpService } from './../../../../services/generic-http.service';
import { EmpresaEntity } from './../../entitys/empresa.entity';
import { MotoristasEntity } from './../../entitys/motoristas.entity';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractInsertEdit, InsertEditConfig } from '@datagrupo/dg-crud';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-motoristas-inser-edit',
  templateUrl: './motoristas-inser-edit.component.html',
  styleUrls: ['./motoristas-inser-edit.component.scss']
})
export class MotoristasInserEditComponent extends AbstractInsertEdit<MotoristasEntity> implements OnInit {

  empresas: EmpresaEntity[] = []

  public form = new FormGroup({
    nome: new FormControl(''),
    rg: new FormControl(''),
    cpf: new FormControl(''),
    telefone: new FormControl(''),
    cnh: new FormControl(''),
    empresa: new FormControl('')
  })

  constructor(

    private service: GenericHttpService,
    public config: InsertEditConfig,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(config, { path: environment.api.URI, context: 'motorista' })
    service.get('empresa').subscribe(resp => {
      this.empresas = resp.data
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
      empresa: this.entity.empresas?.map(val => {
        return val.id
      }),
    })
  }


  initNewEntity(): void {
    this.entity = new MotoristasEntity()
  }

  saveOrUpdateEntity() {

    const { empresa, nome, rg, cnh, cpf, telefone, ...form } = this.form.value;


    this.entity = {
      ...this.entity,
      ...form,
      nome: !!nome ? nome : undefined,
      id: this.entityId,
      rg: !!rg ? rg : undefined,
      cnh: !!cnh ? cnh : undefined,
      cpf: !!cpf ? cpf : undefined,
      telefone: !!telefone ? telefone : undefined,
      empresas: (empresa || []).map((val: string) => {
        return { id: val }
      })
    }

    if (this.entityId == undefined) {
      this.service.post('motorista', this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Motorista cadastrado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
      )

    } else {
      this.service.patch(`motorista/${this.entity.id}`, this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Motorista editado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
      )
    }
  }

}

