import { Component, HostListener, OnInit, inject } from '@angular/core';
import { AbstractInsertEdit, clearEntity, HttpClient, InsertEditConfig, InterfDirectiveSaveEvent } from "@datagrupo/dg-crud";
import { EmpresaEntity } from "../../entitys/empresa.entity";
import { environment } from "../../../../../environments/environment";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { GenericHttpService } from 'src/app/services/generic-http.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import Swal from 'sweetalert2';

export interface ContatoEmergenciaInterf{
  email: string;
}

@Component({
  selector: 'app-empresa-inser-edit',
  templateUrl: './empresa-inser-edit.component.html',
  styleUrls: ['./empresa-inser-edit.component.scss']
})
export class EmpresaInserEditComponent extends AbstractInsertEdit<EmpresaEntity> implements OnInit {

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
  });
  addOnBlur = true;
  readonly separatorKeysCodes = [ ENTER, COMMA] as const;
  emailsAlerta: any[] = [];
  contato = new FormControl('', [Validators.required]);
  isError: boolean = false;

  constructor(
    public config: InsertEditConfig,
    public route: ActivatedRoute,
    public router: Router,
    private http: GenericHttpService
  ) {
    super(config, { path: environment.api.URI, context: 'empresa' })
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity
    })
    if(this.entity.emailsAlerta){
      this.emailsAlerta = this.entity.emailsAlerta.split(';')
    }
  }

  initNewEntity(): void {
    this.entity = new EmpresaEntity()
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.emailsAlerta.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(fruit: ContatoEmergenciaInterf): void {
    const index = this.emailsAlerta.indexOf(fruit);

    if (index >= 0) {
      this.emailsAlerta.splice(index, 1);
    }
  }

  saveOrUpdateEntity() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if(this.emailsAlerta.length <= 0){
      Swal.fire({
        title: 'Erro',
        text: 'Por favor preencha um contato de emergência',
        icon: 'error',
      })
      this.isError = true;
      return
    }
    this.isError = false;

    const form = this.form.value;

    this.entity = {
      ...this.entity,
      ...form,
      id: this.entityId,
      emailsAlerta: this.emailsAlerta.join(';')
    }


    if (this.entityId == undefined) {
      this.http.post('empresa', this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Empresa cadastrada com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      })
    } else {
      this.http.patch(`empresa/${this.entity.id}`, this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Empresa editada com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      })
    }

  }

}
