<div class="container">
  <div class="col-9">
    <div class="card">
      <div class="card-header">
        <h4>Esqueceu sua senha?</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <h5 class="d-flex justify-content-center">
            Informe seu e-mail para que possa ser enviado um link de restauração
            de sua senha
          </h5>

          <div class="d-flex justify-content-center align-items-center pb-3">
            <div class="col-xl-11 col-lg-11 col-md-12 col-sm-12">
              <label class="form-label">E-mail</label>
              <input [formControl]="email" name="email" type="text" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex">
        <button class="btn btn-success me-3" (click)="enviarEmail()">Enviar</button>
        <button class="btn btn-secondary" (click)="voltar()">Voltar</button>
      </div>
    </div>
  </div>
</div>
