import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissoesMainComponent } from './permissoes-main/permissoes-main.component';
import { PermissoesInsertComponent } from './permissoes-insert/permissoes-insert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { NgxMaskModule } from 'ngx-mask';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';
import { SharedModule } from 'src/app/ui/shared/shared.module';



@NgModule({
  declarations: [
    PermissoesMainComponent,
    PermissoesInsertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    RouterModule,
    DgCrudModule,
    PrivateLayoutModule,
    SharedModule
  ]
})
export class PermissoesModule { }
