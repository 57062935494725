import { Component, OnInit } from '@angular/core';
import { AbstractInsertEdit, HttpClient, InsertEditConfig } from '@datagrupo/dg-crud';
import { PermissoesEntity } from '../../entitys/permissoes.entity';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { timer } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { menuItens } from 'src/app/layouts/private-layout/private-layout/private-layout.component';
import { SessionService } from 'src/app/core/services/session/session.service';

export interface permissoesInter {
  identificador?: string,
  read?: boolean,
  create?: boolean,
  update?: boolean,
  delete?: boolean,
  ativo?: boolean,
  download?: boolean,
  acoes?: boolean
}
@Component({
  selector: 'app-permissoes-insert',
  templateUrl: './permissoes-insert.component.html',
  styleUrls: ['./permissoes-insert.component.scss']
})
export class PermissoesInsertComponent extends AbstractInsertEdit<PermissoesEntity> implements OnInit {

  nomePermissao = new FormControl('', Validators.required)

  permissoes: permissoesInter[] | any = [];

  identificadorPermissao: string | undefined;

  isCheck: boolean = false;

  constructor(
    private confi: InsertEditConfig,
    private http: HttpClient,
    private session: SessionService
  ) {
    super(confi, { path: environment.api.URI, context: 'permissao' });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    menuItens[0].menuList.forEach(v => {
      this.permissoes?.push(
        {
          identificador: v.nome,
          read: false,
          create: false,
          update: false,
          delete: false,
          ativo: false,
          download: false,
          acoes: false
        }
      )
    })
  }

  override initNewEntity(): void {
    this.entity = new PermissoesEntity()
  }

  override afterFetchEntity(): void {
    this.nomePermissao.setValue(this.entity.nomePermissao)
    this.permissoes = this.entity.permissoes
    this.identificadorPermissao = this.entity?.nomePermissao;
  }

  checkIdentificador(identificador: string | undefined) {
    if (
      identificador == 'Monitoramento'
      || identificador == 'Dashboard'
      || identificador == 'Logs do Sistema'
      || identificador == 'Contatos'
      || identificador == 'Gerenciar Dados'
    ) {
      return false
    }
    return true
  }

  checkAll(row: any) {
    this.isCheck = row.target.checked
    for (let i = 0; i < this.permissoes.length; i++) {
      this.permissoes[i] = {
        identificador: this.permissoes[i].identificador,
        read: row.target.checked,
        create: row.target.checked,
        update: row.target.checked,
        delete: row.target.checked,
        ativo: this.permissoes[i].identificador == "Empresas" ? row.target.checked : false,
        download: this.permissoes[i].identificador == "Viagens" ? row.target.checked : false,
        acoes: this.permissoes[i].identificador == "Viagens" ? row.target.checked : false
      }
    }
  }

  saveOrUpdateEntity() {
    if (!!this.nomePermissao.invalid) {
      this.nomePermissao.markAllAsTouched();
      return;
    }

    if (!this.verificaListaPermissoes()) {
      return
    };


    this.entity = {
      ...this.entity,
      nomePermissao: this.nomePermissao.value,
      permissoes: this.permissoes
    }

    if (this.entityId) {
      if (this.identificadorPermissao == this.session.user?.nomePermissao) {
        Swal.fire({
          title: 'Deseja alterar a sua permissão?',
          text: 'Você será redirecionado para o login',
          icon: 'info',
          confirmButtonColor: 'blue',
          cancelButtonColor: 'red',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          showCancelButton: true
        }).then(confirm => {
          if (confirm.isConfirmed) {
            this.http.patch(environment.api.URI + `permissao/${this.entityId}`, this.entity).subscribe(
              resp => {
                Swal.fire({
                  title: 'Permissão editada com sucesso',
                  icon: 'success',
                  timer: 3000,
                  confirmButtonColor: 'blue'
                }).then(() => {
                  this.session.logout()
                })
              }
            )
          }
        })
      } else {
        this.http.patch(environment.api.URI + `permissao/${this.entityId}`, this.entity).subscribe(
          resp => {
            Swal.fire({
              title: 'Permissão editada com sucesso',
              icon: 'success',
              timer: 3000,
              confirmButtonColor: 'blue'
            }).then(() => {
              this.voltar()
            })
          }
        )
      }

    } else {
      this.http.post(environment.api.URI + 'permissao', this.entity).subscribe(
        resp => {
          Swal.fire({
            title: 'Permissão criada com sucesso',
            icon: 'success',
            timer: 3000,
            confirmButtonColor: 'blue'
          }).then(() => {
            this.voltar()
          })
        }
      )
    }

  }

  verificaListaPermissoes(): boolean {
    let cont = 0;
    for (let i = 0; i < this.permissoes.length; i++) {
      if (this.permissoes[i].read == false) {
        cont = cont + 1
      }

    }
    if (this.permissoes.length == 0) {
      Swal.fire({
        title: 'Erro',
        text: 'Por favor selecione uma permissão de observação.',
        icon: 'error',
        confirmButtonColor: 'blue'
      })
      return false
    }
    return true
  }


}
