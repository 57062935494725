import { ActivatedRoute, Router } from '@angular/router';
import { GenericHttpService } from './../../../../services/generic-http.service';
import { EmpresaEntity } from './../../entitys/empresa.entity';
import { Component, OnInit } from '@angular/core';
import { AbstractInsertEdit, InsertEditConfig } from '@datagrupo/dg-crud';
import { CaminhoesEntity } from '../../entitys/caminhoes.entity';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-caminhoes-inser-edit',
  templateUrl: './caminhoes-inser-edit.component.html',
  styleUrls: ['./caminhoes-inser-edit.component.scss'],
})
export class CaminhoesInserEditComponent extends AbstractInsertEdit<CaminhoesEntity> implements OnInit {
  empresas: EmpresaEntity[] = [];

  public form = new FormGroup({
    marca: new FormControl(''),
    anoFabricacao: new FormControl(''),
    anoModelo: new FormControl(''),
    placa: new FormControl(''),
    empresa: new FormControl('')
  });

  constructor(
    public config: InsertEditConfig,
    private service: GenericHttpService,

  ) {
    super(config, { path: environment.api.URI, context: 'caminhao' })
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.service.get('empresa').subscribe((resp) => {
      this.empresas = resp.data;
    });
  }

  initNewEntity(): void {
    this.entity = new CaminhoesEntity();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
      empresa: this.entity.empresas?.map(v => {
        return v.id
      })
    })
  }


  

  saveOrUpdateEntity() {
    

    const { marca, placa, anoFabricacao, anoModelo, empresa, ...form } = this.form.value;

    

    this.entity = {
      ...this.entity,
      ...form,
      id: this.entityId,
      anoFabricacao: !!anoFabricacao ? anoFabricacao: undefined,
      anoModelo: !!anoModelo ? anoModelo: undefined,
      placa: !!placa ? placa: undefined,
      marca: !!marca ? marca: undefined,
      empresas: !!empresa ? empresa.map((v: any) => {
        return{ id: v }
      }) : []
    };

    if (this.entityId != undefined) {
      this.service.patch(`caminhao/${this.entity.id}`, this.entity).subscribe(() => {
        Swal.fire({
          title: 'Caminhão editado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
      )
    } else {
      this.service.post('caminhao', this.entity).subscribe(() => {
        Swal.fire({
          title: 'Caminhão cadastrado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
      )
    }
  }

}
