export const environment = {
  production: true,
  environmentName: "prod",
  endpointSocket: 'https://api.sety.tech',
  apiUrlMock: 'http://localhost:4000/',
  api: {
    URI: 'https://api.sety.tech/',
    recaptcha: "6LeXXt0fAAAAAMzc2nVUzhZFLXX_kOGJJCNrFB9i",
    token: null
  }
};
