<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-header row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <h4 class="card-title">Informações para Contato</h4>
        </div>
      </div>
      <div class="card-body">
        <p class="text-center" style="font-size: 13px;">
          <br />
          <br />
          <strong>Razão Social.</strong>
          <br />
          SETY COMERCIO E TECNOLOGIA LTDA.
          <br />
          CNPJ 33.517.436/0001-01
          <br />
          <br />
          <strong>Endereço completo.</strong>
          <br />
          SETY COMERCIO E TECNOLOGIA LTDA - Nº 305 - SALA 1105.
          <br />
          PARQUE RESIDENCIAL AQUARIUS.
          <br />
          SAO JOSE DOS CAMPOS - SP.
          <br />
          CEP 12.246-000.
          <br />
          <br />
          <br />
        </p>
        <h3 style="font-weight: bold; font-size: 16px;" class="text-center">E-mails e Telefones para Contato</h3>
        <br />
        <p class="text-center" style="font-size: 13px;">
          <strong>Central de Atendimento</strong>
          <br />
          <strong>E-mail:</strong>contato@setytecnologia.com.br
          <br />
          <strong>Telefone:</strong> (12) 3346-4555
          <br />
          <br />

          <strong>Dep. Suporte Técnico</strong>
          <br />
          <strong>E-mail:</strong>manutencao@setytecnologia.com.br
          <br />
          <strong>Telefone:</strong> (12) 99637-7299 (Emergência 24h)
          <br />
          <br />

          <strong>Dep. Comercial</strong>
          <br />
          <strong>E-mail:</strong>comercial@setytecnologia.com.br
          <br />
          <strong>Telefone:</strong> (12) 98111-8608
          <br />
          <br />

          <strong> Dep. Financeiro (CP e CR)</strong>
          <br />
          <strong>E-mail:</strong>administracao@setytecnologia.com.br
          <br />
          <strong>Telefone:</strong> (12) 98842-7393
          <br />
          <br />
        </p>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>