import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PublicRoutingModule } from './public-routing.module';
import { EsqueceuSenhaComponent } from './esqueceu-senha/esqueceu-senha.component';
import { RedefinirSenhaComponent } from './redefinir-senha/redefinir-senha.component';



@NgModule({
  declarations: [
    LoginComponent,
    EsqueceuSenhaComponent,
    RedefinirSenhaComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    PublicRoutingModule
  ]
})
export class PublicModule { }
