import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContatosMainComponent } from './contatos-main/contatos-main.component';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';



@NgModule({
  declarations: [
    ContatosMainComponent
  ],
  imports: [
    CommonModule,
    PrivateLayoutModule
  ]
})
export class ContatosModule { }
