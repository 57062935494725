import { Component, Input, OnInit } from '@angular/core';
import { DgSidebarMenuComponent } from '@datagrupo/dg-ng-util';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  urlMinhaColeta = '';

  public sidebaOpened2 = true

  constructor() {
    this.urlMinhaColeta = window.location.origin
  }

  ngOnInit(): void {
    document.getElementsByClassName('router-outlet-root')[0].classList.add('pagind-footer');
  }

  sidebaOpened() {
    return DgSidebarMenuComponent.open
  }
}
