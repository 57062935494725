import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { lacreInterf } from '../monitoramento-main/monitoramento-main.component';
@Injectable({
  providedIn: 'root',
})
export class MonitoramentoService {
  socket!: Socket;
  readonly url: string = 'http://127.0.0.1:2000';

  observable: Observable<lacreInterf> | undefined
  msg: Observable<any> | undefined

  constructor() {
  }

  connect(token:string){
    this.socket = io(environment.endpointSocket, {
      auth: {
        token: `Bearer ${token}`,
      },
      extraHeaders: {
      'Authorization':`Bearer ${token}`
    }});

    this.socket.on('connect', () => {
      this.socket.emit('global:lacreAlarm:subscribe')
    })

  }

  disconect() {
    this.socket.disconnect();
  }

  disconectOne(id: string) {
    this.socket.off(id)
  }

  getMonitor(vid: string) {
    this.socket.emit('msgFrontToBack', { imei: vid }, (msg: any) => {
      console.log(`-> getMonitor ${vid}`);
    });
  }

  lockOrUnlock(data: any) {
    this.socket.emit('lockOrUnlock', data.id, data.lockOrUnlock)
  }

  passwordUnlock(data: any) {
    this.socket.emit('passwordUnlock', data.id, '12345678')
  }

  sendAction(data: any) {
    this.socket.emit('sendAction', {receipt: data}, (msg: any) => {
      console.log(msg)
    })
  }
}
