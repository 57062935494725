import { Component, OnInit } from '@angular/core';
import { UsuarioEntity } from '../../entitys/usuario.entity';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { InterfaceCustomActions, crudDispatchEvent } from "@datagrupo/dg-crud";
import { SessionService } from 'src/app/core/services/session/session.service';


@Component({
  selector: 'app-usuario-main',
  templateUrl: './usuario-main.component.html',
  styleUrls: ['./usuario-main.component.scss']
})
export class UsuarioMainComponent implements OnInit {

  public usuariosList: UsuarioEntity[] = []

  public mapEntity = new UsuarioEntity()

  customActions: InterfaceCustomActions[] = []

  permissao: any;

  constructor(
    public http: HttpClient,
    public route: Router,
    private session: SessionService
  ) {
    this.permissao = session.user.permissoes?.find(v => v.identificador?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() == 'usuarios')
    if (this.permissao?.read) {
      this.customActions.push(
        {
          name: 'Editar',
          action: !!this.permissao.update ? (row: UsuarioEntity) => this.navigate(row.id) : () => { },
          permission: !!this.permissao.update ? true : false
        },
        {
          name: 'Excluir',
          action: !!this.permissao.delete ? (row: UsuarioEntity) => this.delete(row) : () => { },
          permission: (row: UsuarioEntity) => {
            if (this.permissao.delete == true  && row.cargo != 'SETY') {
              return true
            }
            return false
          }
        },
      )
    }
  }

  ngOnInit(): void {
  }

  dictionary = (data: any) => {
    return data
  }

  navigate(index: string | number | undefined) {
    this.route.navigate([`/user/usuarios/${index}`])
  }

  delete(row: UsuarioEntity) {
    Swal.fire({
      title: 'Excluir registro',
      text: 'Deseja excluir esse registro? Esta ação é permanente.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        this.http.delete(environment.api.URI + `usuario/${row.id}`).subscribe(resp => {
          Swal.fire({
            title: 'Deletado com sucesso',
            text: 'Registro deletado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          crudDispatchEvent('usuarios')
        })
      }

    })
  }


}
