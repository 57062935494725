import { HostListener, Injectable } from '@angular/core';
import { AbstractSessionService } from '@datagrupo/dg-ng-util';
import { TokenService } from '../token.service';
import { permissoesInter } from 'src/app/pages/private/permissoes/permissoes-insert/permissoes-insert.component';
import { stringify } from '@angular/compiler/src/util';
import { Router } from '@angular/router';

class UserAppEntity {
  id?: number | string;
  permissoes?: permissoesInter[];
  nomePermissao?: string
}

@Injectable({
  providedIn: 'root'
})
export class SessionService extends AbstractSessionService {

  user: UserAppEntity = {};

  menu: any;

  constructor(
    public token: TokenService,
    private route: Router
  ) {
    super(token)
    this.menu = JSON.parse(String(window.localStorage.getItem('menu')));
    this.pathRedirectLogout = '/'
  }

  setUser(): void {
    let token: any = this.token.abrirToken();
    this.user.id = token.sub;
    this.user.permissoes = token.permissoes;
    this.user.nomePermissao = token.nomePermissao
  }

}