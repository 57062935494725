import { MatSelectModule } from '@angular/material/select';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaminhoesMainComponent } from './caminhoes-main/caminhoes-main.component';
import { CaminhoesInserEditComponent } from './caminhoes-inser-edit/caminhoes-inser-edit.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';
import { SharedModule } from 'src/app/ui/shared/shared.module';



@NgModule({
  declarations: [
    CaminhoesMainComponent,
    CaminhoesInserEditComponent
  ],
  imports: [
    CommonModule,
    NgxMaskModule,
    RouterModule,
    ReactiveFormsModule,
    DgCrudModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    PrivateLayoutModule,
    SharedModule
  ]
})
export class CaminhoesModule { }
