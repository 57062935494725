import { Injectable } from '@angular/core';
import { HttpClient } from '@datagrupo/dg-crud';
import { AbstractTokenService } from '@datagrupo/dg-ng-util';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface token {
  data: string,
  exp?: number
  permissoes: string[],
  nomePermissao?: string
}

@Injectable({
  providedIn: 'root'
})

export class TokenService extends AbstractTokenService<token>{

  path_refreshToken = environment.api.URI + 'auth/refresh';

  constructor(public http: HttpClient) {
    super(http);
    this.name_headerToken_refresh = 'Authorization'
  }

 public override refreshToken(): Observable<any> {
    return this.http.post(this.path_refreshToken, {
      token: String(window.localStorage.getItem(this.name_localStoragy_token))
    }, {
      headers: {
        [this.name_headerToken_refresh]: (this.addBearer ? this.bearer : '') + this.getRefreshToken()
      }
    });
  }

}
