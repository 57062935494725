import { MatSelectModule } from '@angular/material/select';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViagensMainComponent } from './viagens-main/viagens-main.component';
import { ViagensInserEditComponent } from './viagens-inser-edit/viagens-inser-edit.component';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';
import { SharedModule } from 'src/app/ui/shared/shared.module';



@NgModule({
  declarations: [
    ViagensMainComponent,
    ViagensInserEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    RouterModule,
    DgCrudModule,
    MatSelectModule,
    MatMenuModule,
    DragDropModule,
    MatIconModule,
    MatTooltipModule,
    PrivateLayoutModule,
    SharedModule
  ]
})
export class ViagensModule { }
