<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4>Cadastro de permissões</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <label>Nome da permissão</label>
                            <input type="text" class="form-control" [formControl]="nomePermissao">
                        </div>
                        <!-- <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12" style="padding-top: 2em;">
                            <input id="markAll" class="form-label" type="checkbox" (click)="checkAll($event)">
                            <label for="markAll" class="ms-2">{{!!isCheck ? 'Desmarcar todos' : 'Marcar todos'}}</label>
                        </div> -->

                    </div>
                    <div class="row mt-3">
                        <div *ngFor="let permission of permissoes" class="col-xl-3 col-lg-3 col-md-12 col-sm-12">

                            <h5>{{permission.identificador}}</h5>
                            <div class="row">
                                <div>
                                    <input type="checkbox" [checked]="permission.read"
                                        (change)="permission.read = !permission.read">
                                    <label class="form-label ms-2">{{permission.identificador != 'Gerenciar Dados' ?
                                        'Observar' : 'Gerenciar Dados'}}</label>
                                </div>
                                <div *ngIf="checkIdentificador(permission.identificador)">
                                    <input type="checkbox" [checked]="permission.create"
                                        (change)="permission.create = !permission.create">
                                    <label class="form-label ms-2">Criar</label>
                                </div>
                                <div *ngIf="checkIdentificador(permission.identificador)">
                                    <input type="checkbox" [checked]="permission.update"
                                        (change)="permission.update = !permission.update">
                                    <label class="form-label ms-2">Editar</label>
                                </div>
                                <div *ngIf="checkIdentificador(permission.identificador)">
                                    <input type="checkbox" [checked]="permission.delete"
                                        (change)="permission.delete = !permission.delete">
                                    <label class="form-label ms-2">Deletar</label>
                                </div>
                                <div *ngIf="permission.identificador == 'Empresas'">
                                    <input type="checkbox" [checked]="permission.ativo"
                                        (change)="permission.ativo = !permission.ativo">
                                    <label class="form-label ms-2">Ativar/Desativar</label>
                                </div>
                                <div *ngIf="permission.identificador == 'Viagens'">
                                    <input type="checkbox" [checked]="permission.download"
                                        (change)="permission.download = !permission.download">
                                    <label class="form-label ms-2">Baixar</label>
                                </div>
                                <div *ngIf="permission.identificador == 'Viagens'">
                                    <input type="checkbox" [checked]="permission.acoes"
                                        (change)="permission.acoes = !permission.acoes">
                                    <label class="form-label ms-2">Ações do Sistema</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-footer>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
                            <button class="btn btn-success" (click)="saveOrUpdateEntity()">Salvar</button>
                        </div>
                    </app-footer>
                </div>
            </div>
        </div>
    </div>