<div class="container">
  <div class=" d-flex justify-content-center col-9">
    <div class="card">
      <div class="d-flex justify-content-center card-header">
        <h4>Esqueceu sua senha?</h4>
      </div>
      <div class="card-body">
        <div class="d-flex row justify-content-center">
          <h5 class="d-flex justify-content-center">
            Crie sua nova senha
          </h5>
          <div class="col-10 ">
            <form [formGroup]="form">

            <div >
              <label>Nova senha</label>
              <input formControlName="novaSenha" type="text" class="form-control">
            </div>

            <div >
              <label>Confirme senha</label>
              <input formControlName="confirmarSenha" type="text" class="form-control">
            </div>

            </form>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <button class="btn btn-success me-3" (click)="enviarSenha()" >Enviar</button>
        <button class="btn btn-secondary" (click)="voltar()">Voltar</button>
      </div>
    </div>
  </div>
</div>
