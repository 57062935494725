import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractInsertEdit, InsertEditConfig } from '@datagrupo/dg-crud';
import { DgSidebarMenuComponent } from '@datagrupo/dg-ng-util';
import { GenericHttpService } from 'src/app/services/generic-http.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.scss']
})
export class EsqueceuSenhaComponent implements OnInit {

  email= new FormControl('', [Validators.required, Validators.email])



  constructor(
    public config: InsertEditConfig,
    public route: ActivatedRoute,
    public router: Router,
    private http: GenericHttpService
  ) {

  }

  ngOnInit(): void {

  }

  voltar() {
    this.router.navigate(['/login'])
  }

  enviarEmail() {
    if(this.email.invalid){
      this.email.markAsTouched()
      return;
    }
    this.http.get(`auth/esquecisenha/${this.email.value}`).subscribe(resp => {
      Swal.fire({
        title: 'Um link será enviado para seu e-mail para redefinir sua senha',
        icon: 'success',
        confirmButtonColor: 'blue',
        timer: 4000
      }).then(() => {
        this.voltar()
      })
    })
  }





}
