import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../../services/session/session.service';
import { TokenService } from '../../services/token.service';
import { MenuService } from '../../services/menu-service/menu-service.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  constructor(
    private session: SessionService,
    private token: TokenService,
    private router: Router,
    private menuService: MenuService
  ) { }

  menu: any;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.token.issetToken()) {
      this.session.logout();
    }

    if (!this.token.checkExp()) {
      this.session.logout();
    }

    if (!this.session.user?.id) {
      this.session.setUser()
    }

    this.menuService.redirect()

    return true;
  }


}