import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoramentoMainComponent } from './monitoramento-main/monitoramento-main.component';
import { NgxMaskModule } from "ngx-mask";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DgCrudModule } from "@datagrupo/dg-crud";
import { MatIconModule } from '@angular/material/icon';
import { MonitoramentoService } from './service/monitoramento.service';
import { SharedModule } from 'src/app/ui/shared/shared.module';
import { PrivateLayoutModule } from 'src/app/layouts/private-layout/private-layout.module';
import { CdkDynamicGroupModule, CdkDynamicTableModule, DgPaginatorModule } from '@datagrupo/dg-ng-util';


@NgModule({
  declarations: [
    MonitoramentoMainComponent,
  ],
  imports: [
    CommonModule,
    NgxMaskModule,
    RouterModule,
    ReactiveFormsModule,
    DgCrudModule,
    MatIconModule,
    FormsModule,
    SharedModule,
    PrivateLayoutModule,
    DgPaginatorModule,
    CdkDynamicTableModule,
    CdkDynamicGroupModule
  ]
})
export class MonitoramentoModule { }
