import { GenericHttpService } from './../../../../services/generic-http.service';
import { EmpresaEntity } from './../../entitys/empresa.entity';
import { AbstractInsertEdit, InsertEditConfig, InterfPagination } from '@datagrupo/dg-crud';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { UsuarioEntity } from './../../entitys/usuario.entity';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { PermissoesEntity } from '../../entitys/permissoes.entity';
import { SessionService } from 'src/app/core/services/session/session.service';

@Component({
  selector: 'app-usuario-inser-edit',
  templateUrl: './usuario-inser-edit.component.html',
  styleUrls: ['./usuario-inser-edit.component.scss']
})
export class UsuarioInserEditComponent extends AbstractInsertEdit<UsuarioEntity> implements OnInit {

  empresas: EmpresaEntity[] = [];
  permissoes: PermissoesEntity[] = [];

  empresaAssociada: any = '';
  empresasAssociadas: any[] = [];
  listEmpresasAssociadas: EmpresaEntity[] = [];
  hasError: boolean = false;

  loading: boolean = false;
  queryId: any;
  podeAssociar: boolean = false;
  pagination: InterfPagination = {
    page: 0,
    size: 10,
    sort: 'id,desc'
  }

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    cargo: new FormControl(''),
    empresa: new FormControl('', [Validators.required]),
    permissao: new FormControl('', [Validators.required])
  })

  constructor(
    public config: InsertEditConfig,
    private service: GenericHttpService,
    public route: ActivatedRoute,
    public router: Router,
    public session: SessionService
  ) {
    super(config, { path: environment.api.URI, context: 'usuario' })
    this.service.get('empresa').subscribe(resp => {
      this.empresas = resp.data;
    });
    this.service.get('permissao').subscribe((resp: any) => {
      this.permissoes = resp.data
    })
    route.params.subscribe(resp => {
      this.queryId = resp['id'];
    })
    if(this.queryId != 'novo' || this.entity?.id){
      if( session.user.nomePermissao == 'SETY'){
        this.buscarEmpresaAssociadas();
      }
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
      empresa: this.entity.empresa?.id,
      permissao: this.entity.permissao?.id
    })
    this.checkId();
    this.getEmpresas();
  }

  checkId() {
    if (this.entity) {
      if (this.entity.id === this.session.user.id) {
        this.form.controls['permissao']?.disable();
      } else {
        this.form.controls['permissao']?.enable();
      }
    }
  }

  initNewEntity(): void {
    this.entity = new UsuarioEntity()
  }

  getEmpresas() {
    if(this.queryId != 'novo' || this.entity?.id){
      
      this.service.get('empresa', { params: { omitFromUserId: this.queryId } }).subscribe(resp => {
        this.listEmpresasAssociadas = resp.data;
      })
    }
  }

  buscarEmpresaAssociadas() {
    this.loading = true
    this.service.get(`permissao/associacoes`,
      {
        params: {
          ...this.pagination,
          usuarioId: this.queryId != 'novo' ? this.queryId : this.entity?.id,
          id: this.session.user.id,
        }
      }
    ).subscribe(resp => {
      this.empresasAssociadas = resp
      //  @ts-ignore
      this.pagination = {
        ...resp.page,
        //  @ts-ignore
        page: resp.page?.number
      }
      this.getEmpresas();
      this.loading = false;
    })
  }

  associarEmpresa(data: EmpresaEntity) {
    if (data.id == undefined) {
      Swal.fire({
        title: 'Erro',
        text: 'Por favor selecione uma empresa',
        icon: 'error'
      })
      this.hasError = true
      return
    }
    const dadosEmpresa = {
      usuarioId: this.entity.id,
      empresaId: data.id
    }
    this.service.post('permissao/associar', dadosEmpresa).subscribe(() => {
      this.buscarEmpresaAssociadas();
    })
    this.empresaAssociada = ''
    this.hasError = false;
  }

  desassociarEmpresa(id: number) {
    Swal.fire({
      title: 'Deseja desassociar esta empresa?',
      text: 'Está ação será permanente',
      icon: 'question',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'blue',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: 'red'
    }).then(opt => {
      if (opt.isConfirmed) {
        this.service.delet(`permissao/desassociar/${id}`).subscribe(
          () => {
            Swal.fire({
              title: 'Deletado com sucesso',
              icon: 'success',
            })
            this.buscarEmpresaAssociadas();
          })
      }
    })
  }

  saveOrUpdateEntity() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { ...form } = this.form.value;


    const senha = Math.random().toString(36).slice(-10)

    this.entity = {
      id: this.entityId != null ? this.entityId : this.entity.id,
      senha: !!this.entityId ? undefined : senha,
      ...form,
    }


    if (this.entity.id == undefined || this.entity.id == null) {
      this.loading = true;
      this.service.post('usuario', this.entity).subscribe(resp => {
        this.loading = false;
        Swal.fire({
          title: 'Deseja associar empresas a esse usuário?',
          icon: 'question',
          confirmButtonColor: 'blue',
          cancelButtonColor: 'red',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((opt) => {
          if(opt.isConfirmed){
            this.podeAssociar = true;
            Swal.fire({
              title: 'Usuário cadastrado com sucesso',
              icon: 'success',
              confirmButtonColor: 'blue',
              timer: 4000
            })
            this.entity = {
              ...resp
            }
            this.getEmpresas();
          }else{
            this.voltar();
            Swal.fire({
              title: 'Usuário cadastrado com sucesso',
              icon: 'success',
              confirmButtonColor: 'blue',
              timer: 4000
            })
          }
        })
      },
      )
    } else {
      this.service.patch(`usuario/${this.entity.id}`, this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Usuário editado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
      )

    }


  }

  changePage(pagination: any) {
    this.pagination = {
      ...this.pagination,
      ...pagination
    }

    this.buscarEmpresaAssociadas()
  }

}
