import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session/session.service';
import { menuItens } from 'src/app/layouts/private-layout/private-layout/private-layout.component';

@Component({
  selector: 'app-start-session',
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.scss']
})
export class StartSessionComponent implements OnInit {

  constructor(
    private router: Router,
    private session: SessionService,
  ) {
  }

  ngOnInit(): void {
    this.startSession();
  }

  startSession() {
    this.session.setUser();
    this.setMenu()

  }

  setMenu() {
    let permissoes: any = this.session.user.permissoes;
    let menu: any = [];
    menuItens[0].menuList.forEach((v, i: number) => {
      if (permissoes[i]?.read == true) {
        menu.push(v)
      }
    })
    window.localStorage.setItem('menu', JSON.stringify(menu))
    this.router.navigate([`${menu[0]?.url}`])
  }

}
