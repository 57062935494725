<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-inline-flex align-items-center justify-content-between">
          <h4>Cadastro de empresa</h4>
          <mat-icon class="fs-5 material-symbols-outlined d-flex"
            matTooltip="Por favor, preencha todos os campos sinalizados com um *" matTooltipPosition="after">
            help
          </mat-icon>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Nome</label><small style="color: red;"> *</small>
                <input name="nome" formControlName="nome" type="text" class="form-control" />
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label d-inline-flex">CNPJ<small style="color: red;"> *</small>
                  <mat-icon
                    class="fs-5 material-symbols-outlined align-items-center d-flex justify-content-center align-items-center"
                    matTooltip="Padrão do CNPJ: 00.000.000/0000-00" matTooltipPosition="after">
                    help
                  </mat-icon></label>
                <input name="cnpj" formControlName="cnpj" type="text" minlength="14" class="form-control"
                  mask="00.000.000/0000-00" />
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">E-mail</label><small style="color: red;"> *</small>
                <input name="email" formControlName="email" type="text" class="form-control" />
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Contatos de Emergência</label>
                <mat-chip-list #chipList aria-label="Fruit selection">
                  <mat-chip *ngFor="let contato of emailsAlerta" (removed)="remove(contato)">
                    {{contato}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input class="form-control" [ngClass]="isError && emailsAlerta.length <= 0 ? 'error' : 'form-control' "
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
              </div>
            </div>
          </form>
        </div>
        <app-footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
            <button class="btn btn-success" (click)="saveOrUpdateEntity()">Salvar</button>
          </div>
        </app-footer>
      </div>
    </div>
  </div>
</div>