import { AbstractEntity, DataServer, DgFilter_autoComplete, DgFilter_input, DgTableColumn } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';

export interface permissoesInter {
  identificador?: string, 
  read?: boolean, 
  create?: boolean, 
  update?: boolean, 
  delete?: boolean
}

@DataServer({
  path: environment.api.URI,
  context: 'permissao'
})
export class PermissoesEntity extends AbstractEntity {

  constructor(
    id?: string | number,
    nomePermissao?: string,
    permissoes?: permissoesInter[]
  ) {
    super()
    this.id = id;
    this.nomePermissao = nomePermissao;
    this.permissoes = permissoes
  }

  @DgTableColumn({ columnName: 'Nome da Permissão', resource: ((val:PermissoesEntity)  => !!val ? val : '--'), sort: true })
  nomePermissao: string | undefined;

  permissoes: permissoesInter[] | undefined;

}
