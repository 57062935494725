import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PublicLayoutModule} from "./public-layout/public-layout.module";
import {RouterModule} from "@angular/router";
import {PrivateLayoutModule} from "./private-layout/private-layout.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PrivateLayoutModule,
    PublicLayoutModule,
    RouterModule
  ]
})
export class LayoutsModule { }
