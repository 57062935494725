import { AbstractEntity, DataServer, DgFilter_input, DgTableColumn } from '@datagrupo/dg-crud';
import { environment } from 'src/environments/environment';


export interface interfRotas {
  cep: string,
  descricao?: string,
  logradouro: string,
  numero: string,
  complemento: string,
  bairro: string,
  cidade: string,
  estado: string,
  latitude: string,
  longitude: string  
}
@DataServer({
  path: environment.api.URI,
  context: 'rota'
})


export class RotasEntity extends AbstractEntity {

  constructor(
    id?: string | number,
    nome?: string,
    rotas?: interfRotas[]
  ) {
    super()
    this.id = id;
    this.nome = nome;
    this.rotas = Array.isArray(rotas) ? rotas : [];
  }

  @DgFilter_input('text', {name: 'Rota'})
  @DgTableColumn({ columnName: 'Nome da rota', resource: (val: string) => !!val ? val : '--', sort: true })
  public nome: string | undefined;

  public rotas: interfRotas[] | undefined

}
